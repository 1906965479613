import styled from '@emotion/styled';
import { formatPrice } from '@utils';

const RowLabel = styled.span`
  z-index: 10;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-weight: 500;
`;
const RowValue = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: #222127;
  padding: 0.5rem;
  background-color: #ffffff;
`;
type propsType = { label: string; value: any; minus?: boolean; show?: boolean ; dataTestId? : string};

export function RowWithValue({
  label,
  value,
  minus = false,
  show = true,
  dataTestId = null, 
}: propsType) {
  return (
    show && (
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <RowLabel>{label}</RowLabel>
        <RowValue data-testid={dataTestId}>{`${minus ? '-' : ''}${formatPrice(value)}`}</RowValue>
      </div>
    )
  );
}
