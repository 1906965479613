import { createPaymentSource } from '@app/payment';
import { useNetworkState } from '@hooks/useNetworkState';
import { NewPaymentDetails } from '@interfaces';
import { authenticateCardOnStripe } from '@utils/paymentGateways';
import StripePaymentDetailsForm from '../../forms/PaymentDetails/StripePaymentDetailsForm';
import { IAddress } from 'teddly-sdk/lib/api/Checkout/types';
import { useCheckoutPaymentContext } from '../PaymentMethodStep/context';
import { CountryCode } from 'teddly-sdk';
import {
  loadStripe,
  StripeCardElement,
  StripeCardNumberElement,
} from '@stripe/stripe-js';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { PaymentGatewayType } from '@app/payment/types';
import { StripeAuthData } from '../../context/checkout-flow';
import {
  SnackbarVariant,
  usePageLayout,
} from '@components/Content/PageLayout/context';
import { StripeCardData } from '../../hooks';

type Props = {
  authData: StripeAuthData;
  onAddPaymentCard: (address: IAddress) => void;
  onBillingAddress: (address: IAddress) => void;
};
function StripeAddPaymentCard({
  authData,
  onAddPaymentCard,
  onBillingAddress,
}: Props) {
  const { state, setLoading, setError, setCompleted, setIdle } =
    useNetworkState();
  const {
    closeCurrentDialog,
    changeCardHandler,
    currentOpenDialog,
    openPickPaymentForm,
    isOpenAddPaymentForm,
    handleBillingAddress,
    isPaymentLoading
  } = useCheckoutPaymentContext();

  const { addSnackbar, closeSnackbar } = usePageLayout();

  const loading = state.status === 'loading' || isPaymentLoading;

  const stripe = useStripe();

  const handleSubmit = ({
    card,
    cardInputData,
    billingAddress,
  }: {
    card?: StripeCardElement | StripeCardNumberElement;
    cardInputData?: NewPaymentDetails;
    billingAddress?: IAddress;
  }) => {
    setLoading();
    onBillingAddress({
      ...billingAddress,
    });
    authenticateCardOnStripe({ card, cardInputData, billingAddress, stripe })
      .then((token) => {
        createPaymentSource(PaymentGatewayType.STRIPE, {
          authenticationData: JSON.stringify({ token: token.token.id }),
          billingAddress: {
            ...billingAddress,
            ['tag']: undefined,
            country: billingAddress.country.code as CountryCode,
          },
        })
          .then(({ success, errors }) => {
            if (success) {              
              setCompleted({ cardInputData, token });
              const cardFromToken = token?.token?.card;
              const selectedCardInfo: StripeCardData = {
                gateway: 'stripe',
                creditCardInfo: {
                  billingAddress: {
                    city: billingAddress?.city,
                    companyName: billingAddress?.companyName,
                    country: billingAddress?.country?.code,
                    countryArea: billingAddress?.countryArea,
                    firstName: billingAddress?.firstName,
                    lastName: billingAddress?.lastName,
                    phone: billingAddress?.phone,
                    postalCode: billingAddress?.postalCode,
                    note: billingAddress?.note,
                    streetAddress1: billingAddress?.streetAddress1,
                    streetAddress2: billingAddress?.streetAddress2,
                    __typename: 'CardBillingAddress',
                  },
                  __typename: 'CreditCard',
                  expYear: cardFromToken?.exp_year,
                  lastDigits: cardFromToken?.last4,
                  brand: cardFromToken?.brand,
                  expMonth: cardFromToken?.exp_month,
                  cardId: token?.token?.id,
                  firstDigits: null,
                },
              };
              changeCardHandler(selectedCardInfo);
              onAddPaymentCard(billingAddress);
              addSnackbar({
                id: 'success',
                onClose: () => closeSnackbar('success'),
                message: 'Payment card is added successfully!',
                variant: SnackbarVariant.SUCCESS,
              });
            }
            if (errors?.length > 0) {
              throw errors[0];
            }
          })
          .catch((e) => setError(e));
      })
      .catch((e) => {
        let error;
        if (typeof e === 'object' && e.length) {
          error = e[0].text;
        }
        setError(error ? error : e);
        addSnackbar({
          id: 'error',
          onClose: () => closeSnackbar('error'),
          message: state.error || 'failed',
          variant: SnackbarVariant.ERROR,
        });
      });
  };

  const handleClose = () => {
    closeCurrentDialog();
    openPickPaymentForm();
  };

  return (
    <>
      <StripePaymentDetailsForm
        isOpen={isOpenAddPaymentForm}
        onClose={handleClose}
        onSubmit={handleSubmit}
        loading={loading}
      />
    </>
  );
}

export default function StripeAddPaymentWarpper({
  authData,
  onAddPaymentCard,
  onBillingAddress,
}: Props) {
  const stripePromise = loadStripe(authData.apiKey);
  return (
    <Elements stripe={stripePromise}>
      <StripeAddPaymentCard
        authData={authData}
        onAddPaymentCard={onAddPaymentCard}
        onBillingAddress={onBillingAddress}
      />
    </Elements>
  );
}
