import { useEffect, useState } from 'react';
import { Chip, List } from '@teddly/teddly-ui-components';
import { TimesThin } from '@anyvision/anv-icons';
import { DetailHeading } from '../styled';

import chipStyles from '../../Chip.module.scss';
import tipStyles from './Tip.module.scss';
import tw, { css } from 'twin.macro';
import { useCheckoutFlow } from '../../context/checkout-flow';

import { useCheckoutPageContext } from '../../context';
import { isNumber } from 'lodash';
import { InputAdornment, TextField } from '@mui/material';
import { RemoveInputArrows } from '../../forms/AgeRestriction/AddBirthdateForm';
import { TipTypeEnum } from 'teddly-sdk';
import OtherTip from './OtherTip';

type TipProps = {
  title: string;
  otherTipInputDataTestId: string;
  otherTipButtonDataTestId: string;
  tipButtonDataTestId: string;
  otherTipSaveButtonDataTestId: string;
};

export default function Tip({
  title,
  otherTipInputDataTestId,
  otherTipButtonDataTestId,
  tipButtonDataTestId,
  otherTipSaveButtonDataTestId,
}: TipProps): JSX.Element {
  const [selected, setSelected] = useState<string>();
  const { checkout, updateCheckout } = useCheckoutFlow();
  const { setIsLoading } = useCheckoutPageContext();
  const [tipOther, setTipOther] = useState('');
  const variants: number[] = [5, 10, 15, 20, 25];
  const [isShowOtherOption, setIsShowOtherOption] = useState(false);

  const onSelectionClear = () => {
    onTipUnselected().then((data) => {
      setSelected(null);
    });
  };

  const onTipSelected = (type: TipTypeEnum, tip: number) => {
    setIsLoading(true);

    updateCheckout({
      checkoutId: checkout?.id,
      note: checkout?.note,
      itemSubstitution: checkout?.itemSubstitution,
      toivelingItems: checkout?.toivelingItems,
      tip: Math.abs(tip),
      tipType: type,
    })
      .then((data) => {
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  const onTipUnselected = () => {
    setIsLoading(true);
    return updateCheckout({
      checkoutId: checkout?.id,
      note: checkout?.note,
      itemSubstitution: checkout?.itemSubstitution,
      toivelingItems: checkout?.toivelingItems,
      tip: 0,
      tipType: TipTypeEnum.PERCENT,
    }).then((data) => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (checkout?.tipPrice) {
      if (
        checkout?.tipPrice?.type == TipTypeEnum.PERCENT &&
        checkout?.tipPrice?.tip != selected
      ) {
        setSelected(checkout?.tipPrice?.tip);
      } else if (
        checkout?.tipPrice?.type == TipTypeEnum.AMOUNT &&
        checkout?.tipPrice?.tip >= 0
      ) {
        setSelected(null);
        setTipOther(checkout.tipPrice.tip);
      }
    }
  }, [checkout?.tipPrice]);

  const onSelectedTip = (selectedVariant: string, variant: string) => {
    if (selected == variant) {
      onSelectionClear();
    } else {
      setIsShowOtherOption(false);

      setSelected(variant);
      onTipSelected(TipTypeEnum.PERCENT, Number(variant));
    }
    setTipOther('');
  };

  const onSelectedTipOther = (tip: string) => {
    onTipSelected(TipTypeEnum.AMOUNT, Number(tip));
  };

  const onChangeTipOther = (tip) => {
    setTipOther(tip);
  };

  return (
    <>
      <div style={{ fontWeight: 500 }}>{title}</div>
      <List isRowDirection={true}>
        {variants.map((variant, index) => {
          return (
            <List.Item>
              <Chip
                data-testid={tipButtonDataTestId + index}
                key={variant}
                label={`${variant}%`}
                leadingIcon={
                  selected == variant.toString() ? (
                    <TimesThin height={30} width={30} />
                  ) : null
                }
                onClick={() => onSelectedTip(selected, variant.toString())}
                className={
                  selected == variant.toString()
                    ? chipStyles.selectedType
                    : chipStyles.microMargin // this is when not clicked
                }
              />
            </List.Item>
          );
        })}
        <List.Item>
          <OtherTip
            data-testid={tipButtonDataTestId}
            otherTipInputDataTestId={otherTipInputDataTestId}
            otherTipButtonDataTestId={otherTipButtonDataTestId}
            otherTipSaveButton={otherTipSaveButtonDataTestId}
            // otherTipSaveButtonDataTestId={otherTipSaveButtonDataTestId}
            onSelectedTipOther={onSelectedTipOther}
            onChangeTipOther={onChangeTipOther}
            title="Other"
            tipOther={tipOther}
            isShowOtherOption={isShowOtherOption}
            setIsShowOtherOption={setIsShowOtherOption}
          />
        </List.Item>
      </List>
    </>
  );
}
