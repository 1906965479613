import { AddressTypeEnum, CountryCode } from 'teddly-sdk';
import { useUserAddresses } from '@app/user/hooks';
import { CurrentOpenDialog } from '../../context';
import AddAddressForm from '../../forms/ShippingAddress/AddAddress';
import EditAddressForm from '../../forms/ShippingAddress/EditAddress';
import PickShippingAddress from '../PickShippingAddress';
import { ErrorMessage } from '../styled';
import { useState } from 'react';
import { useCheckoutShippingAddressContext } from './context';
import {
  UserInfoBlock,
  Button,
  ButtonVariantEnum,
} from '@teddly/teddly-ui-components';
import { getAddressDescription } from '@utils/descriptions';
import {
  SnackbarVariant,
  usePageLayout,
} from '@components/Content/PageLayout/context';

export default function ShippingAddressStep({
  title,
  shippingAddressDataTetsId,
  shippingAddressChangeButtonDataTetsId,
}: {
  title: string;
  shippingAddressDataTetsId: string;
  shippingAddressChangeButtonDataTetsId: string;
}) {
  const {
    selectedShippingAddress,
    openPickAddressForm,
    saveShippingStatus,
    closeCurrentDialog,
    currentOpenDialog,
    saveShippingError,
    shippingMethodError,
    openAddAddressForm,
    shippingMethodStatus,
    resetShippingAddress,
  } = useCheckoutShippingAddressContext();
  const { userAddresses, deleteAddress, updateAddress, loaded: addressesLoading } = useUserAddresses();
  const [isDeleteCardLoading, setIsDeleteCardLoading] =
    useState<boolean>(false);
  const { addSnackbar, closeSnackbar } = usePageLayout();

  function putDataTestIdOnAllAddress() {
    userAddresses.map((add) => ({
      add,
    }));
  }

  const deleteUserAddress = async (id: string) => {
    setIsDeleteCardLoading(true);
    const { errors, user } = await deleteAddress({ addressId: id });
    setIsDeleteCardLoading(false);
    if (errors?.length > 0) {
      addSnackbar({
        id: 'shIppingList',
        onClose: () => closeSnackbar('shIppingList'),
        message: 'An error occurred while deleting the address',
        variant: SnackbarVariant.ERROR,
        autoHideDuration: 2000,
      });
    } else {
      addSnackbar({
        id: 'shIppingList',
        onClose: () => closeSnackbar('shIppingList'),
        message: 'Address remove successfully',
        variant: SnackbarVariant.SUCCESS,
        autoHideDuration: 2000,
      });
    }
    if (user?.addresses && user?.addresses?.length > 0) {
      resetShippingAddress();
    }
  };

  const getTitle = () => {
    if (selectedShippingAddress && selectedShippingAddress?.tag?.name) {
      return selectedShippingAddress.tag.name;
    }
    return title;
  };

  const onOpenAddShippingMethodClick = () => {
    if (userAddresses && userAddresses?.length > 0) {
      openPickAddressForm();
    } else {
      openAddAddressForm();
    }
  };
  return (
    <div>
      <div>
        {selectedShippingAddress ? (
          <UserInfoBlock
            dataTestId={shippingAddressDataTetsId}
            title={getTitle()}
            selected
            description={getAddressDescription(selectedShippingAddress)}
            buttons={[
              <Button
                data-testid={shippingAddressChangeButtonDataTetsId}
                title="Change"
                variant={ButtonVariantEnum.OUTLINE}
                onClick={openPickAddressForm}
                loading={addressesLoading}
                disabled={addressesLoading}
              />,
            ]}
          />
        ) : (
          <UserInfoBlock
            dataTestId={shippingAddressDataTetsId}
            title={title}
            onClick={onOpenAddShippingMethodClick}
            loading={addressesLoading}
            disabled={addressesLoading}
          />
        )}
        {saveShippingStatus === 'error' && (
          <ErrorMessage>{saveShippingError}</ErrorMessage>
        )}
        {shippingMethodStatus === 'error' && (
          <ErrorMessage>{shippingMethodError}</ErrorMessage>
        )}
      </div>
      <AddAddressForm addressType={AddressTypeEnum.SHIPPING} />
      <EditAddressForm addressType={AddressTypeEnum.SHIPPING} />
      <PickShippingAddress
        onDelete={deleteUserAddress}
        isOpen={currentOpenDialog === CurrentOpenDialog.PickAddress}
        onClose={closeCurrentDialog}
        addresses={
          userAddresses && userAddresses.length
            ? userAddresses?.map((add) => ({
                ...add,
              }))
            : []
        }
      />
    </div>
  );
}
