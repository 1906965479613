import { getFirebaseApp } from 'teddly-sdk/lib/firebase';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/messaging';
// eslint-disable-next-line import/no-duplicates
import 'firebase/firestore';
import { Snackbar, Button } from '@teddly/teddly-ui-components';

import {
  useContext,
  createContext,
  ReactNode,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { usePageLayout } from '@components/Content/PageLayout/context';
import { useRoutes } from '@utils/routes';

export const FirebaseContext = createContext<{ firebase: firebase.app.App }>(
  null
);
export function FirebaseProvider({ children }: { children: ReactNode }) {
  const { addSnackbar, closeSnackbar } = usePageLayout();
  const [firebase, setFirebaseApp] = useState(null);
  const { navigateTo } = useRoutes();

  const notificationReceiveUpdate = (transfer_id) => {
    const requestBody = JSON.stringify({
      query: `mutation {notificationReceive(transferId: "${transfer_id}" receiveMode: FOREGROUND) {success}}`,
    });
    fetch(process.env.NEXT_PUBLIC_BACKEND_API_URL, {
      headers: { 'content-type': 'application/json' },
      method: 'POST',
      body: requestBody,
    })
      .then((res) => res.json())
      .then((data) => {
        //  console.log(data);
      })
      .catch((err) => {
        //  console.log(err.message);
      });
  };
  const notificationInteractUpdate = (transfer_id) => {
    const requestBody = JSON.stringify({
      query: `mutation {notificationInteract(transferId: "${transfer_id}") {success}}`,
    });
    fetch(process.env.NEXT_PUBLIC_BACKEND_API_URL, {
      headers: { 'content-type': 'application/json' },
      method: 'POST',
      body: requestBody,
    })
      .then((res) => res.json())
      .then((data) => {
        //  console.log(data);
      })
      .catch((err) => {
        //  console.log(err.message);
      });
  };

  const setFirebaseMessaging = async (firebaseApp) => {
    try {
      if (!(await firebase?.messaging?.isSupported())) {
        return null;
      }
    } catch (e) {
      console.log('firebase isSupport not work:', e);
    }
    const messaging = firebaseApp.messaging();
    // Handle incoming messages. Called when:
    // - a message is received while the app has focus
    // - the user clicks on an app notification created by a service worker
    //   `messaging.onBackgroundMessage` handler.
    messaging.onMessage((payload) => {
      notificationReceiveUpdate(payload.fcmMessageId);
      addSnackbar({
        id: payload.fcmMessageId,
        onClose: () => closeSnackbar(payload.fcmMessageId),
        message: `${payload.notification.title}: ${payload.notification.body}`,
        variant: payload.data['variant'],
        action: payload.data['active_button'] ? (
          <Button
            variant="negative"
            onClick={
              payload.data['internal-link']
                ? () => {
                    closeSnackbar(payload.fcmMessageId);
                    notificationInteractUpdate(payload.fcmMessageId);
                    navigateTo({ route: payload.data['internal-link'] });
                  }
                : () => {}
            }
          >
            {payload.data['active_button']}
          </Button>
        ) : null,
      });
    });
  };

  useEffect(() => {
    getFirebaseApp().then((value) => {
      setFirebaseApp(value);
      setFirebaseMessaging(value);
    });
  }, []);

  return (
    <FirebaseContext.Provider value={{ firebase: firebase }}>
      {children}
    </FirebaseContext.Provider>
  );
}

export const useFirebase = () => {
  const context = useContext(FirebaseContext);

  if (!context) throw new Error('should be used within FirebaseContext');

  return context;
};
