import MethodsList from './MethodsList';
import { getAddressDescription } from '@utils/descriptions';
import { useEffect, useState } from 'react';
import { useRoutes } from '@utils/routes';
import { Address } from 'teddly-sdk';
import { UserAddressType } from '@app/user/hooks';

type ShippingMethodsListProps = {
  methods?: UserAddressType[];
  selectedMethodId?: string;
  deletedMethodId?: string;
  onDeleteMethod?: (id: string) => Promise<void>;
  onEditMethod?: (addressToEdit: UserAddressType) => void;
  onSelectMethod?: (id: string) => Promise<void>;
};

export default function ShippingMethodsList({
  methods = [],
  selectedMethodId,
  onSelectMethod,
  onEditMethod,
  onDeleteMethod,
  ...otherProps
}: ShippingMethodsListProps) {
  const [deletedMethodIds, setDeletedMethodIds] = useState<string[]>([]);
  const { isCurrentPage, pages } = useRoutes();

  const selectMethodHandler = async (id: string) => {
    await onSelectMethod(id);
  };

  const deleteMethodHandler = async (id: string) => {
    setDeletedMethodIds((prev) => [...prev, id]);
    await onDeleteMethod(id);
    setDeletedMethodIds((prev) => {
      prev.filter((e) => e != id);
      return [...prev];
    });
  };
  return (
    <MethodsList
      {...otherProps}
      blockTitle="Shipping Method"
      descriptionFormatter={getAddressDescription}
      methods={methods?.filter(address => address?.isValidToShipping)}
      deletedMethodIds={deletedMethodIds}
      selectedMethodId={selectedMethodId}
      onDeleteCard={deleteMethodHandler}
      // onSelectCard={selectMethodHandler}
      onSelectCard={
        !isCurrentPage[pages.MY_ACCOUNT] ? selectMethodHandler : null
      }
      onEditCard={onEditMethod}
    />
  );
}
