import SignInDialogPage from '../../cypress/pages/authentication/SignInDialogPage.cy';
import index from '../../cypress/fixtures';
import LocalStorageUtils from './localStorage.cy';
import PageLayoutPage from '../../cypress/pages/page-layout/PageLayoutPage.cy';
import NavbarPage from '../../cypress/pages/navbars/navbarPage.cy';

export default class Utils {
  static shortWait() {
    cy.wait(3000);
  }
  static isButtonDisabled(button) {
    button.should('be.disabled');
  }
  static formatDataTestIdSelector(id: string) {
    return `[data-testid="${id}"]`;
  }

  static awaitGraphqlRequest(operationName: string, then: () => void) {
    cy.intercept('POST', '**/graphql/', (req) => {
      req.body.forEach((b) => {
        if (b.operationName == operationName) {
          req.alias = operationName;
        }
      });
    });
    cy.wait(`@${operationName}`).then(then);
  }

  static shouldNotBeEmptyString(str: string) {
    return expect(str).to.not.be.empty;
  }

  static shouldBeEmptyString(str: string) {
    return expect(str).to.equal('');
  }

  static shouldBeNull(element) {
    return expect(element).to.null;
  }
  static loginUsingSignInForum(fixture: string) {
    const pageLayoutPage = new PageLayoutPage();
    const navbarPage = new NavbarPage();
    const signInDialogPage = new SignInDialogPage();
    cy.visit('/');
    navbarPage.clickAccountActionsButton();
    Utils.shortWait();
    cy.fixture(fixture).then(({ email, password }) => {
      signInDialogPage.typeEmail(email);
      signInDialogPage.typePassword(password);
    });
    signInDialogPage.clickSubmitButton();

    // Utils.awaitGraphqlRequest('UserCheckoutTokenList', () => {
    Utils.awaitGraphqlRequest('TokenAuth', () => {
      pageLayoutPage.assertSnackbar('Welcome Back!');
      signInDialogPage.dialog.should('not.exist').then(() => {
        navbarPage.assertAccountActions('account');
        Utils.shouldNotBeEmptyString(
          LocalStorageUtils.getLocalStorageValueBy('token')
        );
      });
    });
  }
  static loginUsingLocalStorage() {
    cy.fixture(index.loginStates.emptyCart).then((state) => {
      console.info(state);
      Object.keys(state).forEach((key) => {
        const value = state[key];
        LocalStorageUtils.setLocalStorageItem(key, value);
      });
    });
  }

  static loginUsingLocalStorageMyNameUser() {
    cy.fixture(index.loginStates.emptyOrders).then((state) => {
      console.info(state);
      Object.keys(state).forEach((key) => {
        const value = state[key];
        LocalStorageUtils.setLocalStorageItem(key, value);
      });
    });
  }

  static loginUsingLocalStoragCheckoutUser() {
    cy.fixture(index.loginStates.addressUser).then((state) => {
      console.info(state);
      Object.keys(state).forEach((key) => {
        const value = state[key];
        LocalStorageUtils.setLocalStorageItem(key, value);
      });
    });
  }
}
