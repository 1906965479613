import { QueryClient, QueryClientProvider } from 'react-query';

// import { TeddlyThemeProvider } from '@components/ui-lib/theme';
import { TeddlySdkProvider } from '@config/teddly-sdk/TeddlySdkProvider';
import { CartProvider } from '@context/cart/cart-provider';
import { CheckoutFlowProvider } from '@components/Pages/Checkout/context/checkout-flow';
import { PageLayoutContextProvider } from '@components/Content/PageLayout/context';
import { DeliveryProvider } from '@components/Pages/Checkout/components/Delivery/context';
import { UserAddressProvider } from '@app/user/hooks';
import { CheckoutPageProvider } from '@components/Pages/Checkout/context';
import { CheckoutShippingAddressProvider } from '@components/Pages/Checkout/components/ShippingAddressStep/context';
import { CheckoutPaymentProvider } from '@components/Pages/Checkout/components/PaymentMethodStep/context';
import { VendorProvider } from '@components/Pages/ShopByStore/context';
import { RoutesContextProvider } from '@utils/routes';
import { FastDeliveryProvider } from '@components/Pages/FastDelivery/context';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { FirebaseProvider } from '@config/firebase/context';
import { SuggestionsContextProvider } from './Suggestions';
import { HomePageProvider } from './homePage/HomePageContext';
import { ChannelProvider } from './channel/ChannelContext';

const queryClient = new QueryClient();
const googleClientID = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID;

export const AppProviders = ({ children }) => (
  <GoogleOAuthProvider clientId={googleClientID}>
    <QueryClientProvider client={queryClient}>
      {/* <TeddlyThemeProvider> */}
      <TeddlySdkProvider>
        <ChannelProvider>
          <PageLayoutContextProvider>
            <RoutesContextProvider>
              <HomePageProvider>
                <FirebaseProvider>
                  <CheckoutPageProvider>
                    <CheckoutFlowProvider>
                      <FastDeliveryProvider>
                        <UserAddressProvider>
                          <CheckoutShippingAddressProvider>
                            <DeliveryProvider>
                              <VendorProvider>
                                <CheckoutPaymentProvider>
                                  <CartProvider>
                                    {/* <SuggestionsContextProvider> */}
                                    {children}
                                    {/* </SuggestionsContextProvider> */}
                                  </CartProvider>
                                </CheckoutPaymentProvider>
                              </VendorProvider>
                            </DeliveryProvider>
                          </CheckoutShippingAddressProvider>
                        </UserAddressProvider>
                      </FastDeliveryProvider>
                    </CheckoutFlowProvider>
                  </CheckoutPageProvider>
                </FirebaseProvider>
              </HomePageProvider>
            </RoutesContextProvider>
          </PageLayoutContextProvider>
        </ChannelProvider>
      </TeddlySdkProvider>
      {/* </TeddlyThemeProvider> */}
    </QueryClientProvider>
  </GoogleOAuthProvider>
);
