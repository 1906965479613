import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import useDisableScroll from '@hooks/useDisableScroll';
import SocialLogin from './SocialLogin';
import {
  Dialog,
  Button,
  InputBase,
  ButtonSizeEnum,
} from '@teddly/teddly-ui-components';
import styles from '@styles/dialog.module.scss';
import { ButtonVariantEnum } from '@teddly/teddly-ui-components';
import { EMAIL_MAX_LENGTH, PASSWORD_MAX_LENGTH } from '@config/validation';
import {
  SnackbarVariant,
  usePageLayout,
} from '@components/Content/PageLayout/context';
import { ids } from '../../../../cypress/pages/authentication/SignInDialogPage.cy';
import { getServerAssetsImageUrl } from '@utils';
import { useChannelContext } from '@context/channel/ChannelContext';
import classNames from 'classnames';

interface FormDataType {
  email?: string;
  password?: string;
}

type Props = {
  signIn?: (data: FormDataType) => Promise<any>;
  isOpen?: boolean;
  errorMessage?: string;
  onClose?: () => void;
  onSignUpClick?: () => void;
  onForgotPasswordClick?: () => void;
  responseGoogle?: (data: any) => void;
  responseFacebook?: (data: any) => void;
  responseApple?: (data: any) => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
};

const emptyFn = () => null;

export default function LoginDialog({
  signIn,
  isOpen = false,
  loading,
  setLoading,
  onClose = emptyFn,
  errorMessage = '',
  onSignUpClick = emptyFn,
  onForgotPasswordClick = emptyFn,
  responseGoogle = emptyFn,
  responseFacebook = emptyFn,
  responseApple = emptyFn,
}: Props): JSX.Element {
  useDisableScroll(isOpen);
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid },
  } = useForm<FormDataType>({ mode: 'onChange' });

  const { isChannelSelected } = useChannelContext();

  const { addSnackbar, closeSnackbar } = usePageLayout();

  const setLoadingMode = (value: boolean) => {
    setLoading(value);
  };

  const openForgotPassword = () => {
    onForgotPasswordClick();
    clearForm();
  };

  const openSignUp = () => {
    onSignUpClick();
    clearForm();
    setLoading(false);
  };

  const showSelectChannelSnackbar = () => {
    const snackbarId = 'selectChannelMessage';
    addSnackbar({
      id: snackbarId,
      onClose: () => closeSnackbar(snackbarId),
      message: 'Firstly, you must type in your zip code, then create account',
      variant: SnackbarVariant.WARNING,
    });
  };

  const clearForm = () => {
    reset({ email: '', password: '' });
  };

  const onSubmit = async (data) => {
    try {
      await signIn(data);
    } finally {
      clearForm();
    }
  };

  const isEmailError = errors?.email?.message.length > 0;
  const isPasswordError = errors?.password?.message.length > 0;
  const passwordFootnote = (error: any) =>
    isPasswordError ? (
      errorMessage ? (
        <span style={{ color: '#ef2d2d' }}>{errorMessage}</span>
      ) : (
        error?.message
      )
    ) : (
      <span
        data-testid={ids.forgotPasswordButton}
        style={{
          color: 'black',
          cursor: 'pointer',
        }}
        onClick={openForgotPassword}
        role="button"
      >
        Forgot Password?
      </span>
    );
  return (
    <>
      <Dialog
        data-testid={ids.dialog}
        isOpen={isOpen}
        title="Login to your account"
        onClose={onClose}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
      >
        <Dialog.Body>
          <form
            className={styles.dialogBodySectionsContainer}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div
              data-testid={ids.heading}
              className={classNames(
                styles.dialogTitle,
                styles.dialogBodySection,
              )}
            >
              Welcome To Teddly
            </div>
            <div className={classNames(styles.dialogBodySection, styles.socialLoginSection)}>
              <SocialLogin
                responseGoogle={responseGoogle}
                responseFacebook={responseFacebook}
                responseApple={responseApple}
                setLoadingMode={setLoadingMode}
              />
            </div>
            <div
              className={classNames(
                styles.dialogBodySection,
                styles.formOrDivider,
              )}
            >
              or
            </div>
            <div
              className={styles.dialogBodySection}
            >
              <Controller
                name="email"
                control={control}
                rules={{
                  maxLength: {
                    value: EMAIL_MAX_LENGTH,
                    message: `email is more then ${EMAIL_MAX_LENGTH} Characters`,
                  },
                  required: 'Email is required !',
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: 'Please enter valid Email !',
                  },
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputBase
                    data-testid={ids.emailInput}
                    type="text"
                    fullWidth
                    label="Email"
                    onChange={onChange}
                    value={value}
                    footnote={error?.message}
                    errored={isEmailError}
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                rules={{
                  maxLength: {
                    value: PASSWORD_MAX_LENGTH,
                    message: 'password is too long',
                  },
                  required: 'Password is required !',
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputBase
                    data-testid={ids.passwordInput}
                    type="password"
                    label="Password"
                    fullWidth
                    onChange={onChange}
                    value={value}
                    footnote={passwordFootnote(error)}
                    errored={isPasswordError}
                  />
                )}
              />
            </div>
            {errorMessage && (
              <span style={{ color: '#ef2d2d' }}>{errorMessage}</span>
            )}
          </form>
          {/* </div> */}
        </Dialog.Body>
        <Dialog.Footer>
          <Button
            data-testid={ids.cancelButton}
            variant={ButtonVariantEnum.NEGATIVE}
            onClick={isChannelSelected ? openSignUp : showSelectChannelSnackbar}
            title="Open an account"
          />
          <Button
            data-testid={ids.submitButton}
            id="login"
            name="login"
            title="login"
            type="submit"
            onClick={handleSubmit(onSubmit)}
            // important: when request sent and is loading, isValid is false Because at this stage it is not possible to enter data
            disabled={!!loading || !isValid}
            loading={!!loading}
          />
        </Dialog.Footer>
      </Dialog>
    </>
  );
}
