import { GraphQLError } from 'graphql';

import { CartableProduct } from '@interfaces';
import moment from 'moment';
import { Timeslot } from '@app/checkout';
import { NavigationProps } from './routes';
import {
  getRootCategoriesBaseFragments,
  getRootCategoriesSlugs,
} from '@services/categories/api';
import { PageInfo } from 'teddly-sdk';
import { SyntheticEvent } from 'react';

export const TABLET_WIDTH = 640;

export const hiddenImageOnError = (e: any) => {
  e.stopPropagation();
  e.target.style.display = 'none';
};

export const setPrevState = (setStateUpdate, key, value) => {
  setStateUpdate((prevState) => ({
    ...prevState,
    [key]: value,
  }));
};
export const getDeliveryDateFormat = (timeslot: Timeslot) => {
  return (
    timeslot?.timeslotMethod?.weekday +
    ',' +
    timeslot?.timeslotMethod?.startHour
  );
};
export function formatPrice(price: string | number, currency = '$'): string {
  if (!price) price = 0;

  if (typeof price === 'string') return `$${Number(price).toFixed(2)}`;

  return `${currency}${price.toFixed(2)}`;
}

export const getGoogleMapsLocationUrl = (latitude: number, longitude: number) =>
  `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}&language=en`;

export const getServerAssetsImageUrl = (imagePathName: string): string =>
  process.env.NEXT_PUBLIC_ASSETS_SERVER_DOMAIN + imagePathName;
export const emptyImageSrc = getServerAssetsImageUrl('empty.png');

export type PricingInfo = {
  price: string; // sale price
  unit: string;
  pricePerUnit: string;
  estPerUnit: string;
  estUnitSize: string;
  priceByQuantity: string;
  isByWeight: boolean;
  regularPrice: string; // listing price
};
export const getPricingInfo = (
  cartableProduct: CartableProduct,
): PricingInfo => {
  const price = Number(
    cartableProduct.is_sale
      ? cartableProduct.sale_price
      : cartableProduct.price,
  );

  const isByWeight = Boolean(cartableProduct.by_weight);

  const unit = isByWeight ? 'lb' : '';
  const pricePerUnit = isByWeight
    ? price * Number(cartableProduct.unit_size)
    : price;
  const priceByQuantity = pricePerUnit * cartableProduct.quantity;

  return {
    price: formatPrice(price),
    priceByQuantity: formatPrice(priceByQuantity),
    pricePerUnit: formatPrice(pricePerUnit),
    estPerUnit: `Est per unit: ${cartableProduct.unit_size} lb / ${formatPrice(
      pricePerUnit,
    )}`,
    estUnitSize: `Est: ${cartableProduct.unit_size} lb`,
    regularPrice: formatPrice(cartableProduct.price),
    isByWeight,
    unit,
  };
};

export const formatGraphQLError = (
  error: GraphQLError[] | string | any,
): string => {
  if (!error) return '';

  if (typeof error === 'string') return error;

  if (error?.message) return error.message;

  if (error?.length) {
    return error.map((e) => e?.message).join(', ');
  }

  return JSON.stringify(error);
};

export const isSizedAttribute = (attr: {
  key: string;
  value: string;
}): boolean => attr?.key === 'case' || attr?.key === 'by_quantity';

export const isSizedProduct = (product: CartableProduct): boolean =>
  Boolean(product?.variant?.attributes?.find(isSizedAttribute));

export const findSizedProduct = (products: CartableProduct[] = []) => {
  const sizedProduct = products.find(isSizedProduct);
  return sizedProduct;
};

export const getSizedProductAttribute = (product: CartableProduct) => {
  return product?.variant?.attributes?.find(isSizedAttribute);
};

export function productsToVariantsBySizeMap(allVariants: CartableProduct[]) {
  return allVariants.reduce((acc, curr) => {
    const size = getSizedProductAttribute(curr);

    if (size && !acc[size.value]) {
      acc[size.value] = curr;
    }
    return acc;
  }, {});
}

export const DEFAULT_NO_PRODUCT_IMAGE = '/assets/images/cactus.png';

export function timeRemaining(endtime: Date): {
  hours: number;
  minutes: number;
  seconds: number;
} {
  const now = Date.now();
  const end = +endtime;

  if (now >= end)
    return {
      hours: 0,
      minutes: 0,
      seconds: 0,
    };

  let delta = Math.abs(end - now) / 1000;

  // calculate (and subtract) whole days
  const days = Math.floor(delta / 86400);
  delta -= days * 86400;

  // calculate (and subtract) whole hours
  const hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  // calculate (and subtract) whole minutes
  const minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;

  // what's left is seconds
  const seconds = Math.floor(delta % 60); // in theory the modulus is not required
  return {
    hours,
    minutes,
    seconds,
  };
}
export const formatPhone = (str: string, position: number, insert: string) => {
  if (!str) return '';
  str = str.replace('+', '');
  return str?.substring(0, position) + insert + str?.substring(position);
};
export const getDefaultIphone = (phone: string) => {
  if (phone) {
    phone = phone[0] === '+' ? phone.slice(1) : phone;
    phone = phone[0] === '1' ? phone.slice(1) : phone;
  }
  return phone;
};

// export const updateLocalCart = async () => {
//   let productData = new Map<String, any>();
//   const objectIDs = [];
//   checkout.lines.forEach((line) => {
//     console.log(line);
//     const variant = line.variant;
//     const objectID = atob(variant.id).split(':')[1];
//     objectIDs.push({
//       indexName: AlgoliaIndexes.PRODUCTS,
//       objectID,
//     });
//     productData.set(objectID, {
//       quantity: line.quantity,
//       note: line.note,
//     });
//   });
//   let products = await searchClient.multipleGetObjects(objectIDs);
//   products.results.forEach((res) => {
//     const data = productData.get(res.objectID);
//     const quantity = data['quantity'];
//     const note = data['note'];
//     const product = res as CartableProduct;
//     console.log(product);
//     addCartVariant(product, quantity, note);
//   });
//   setIsLocalCartUpdated(true);
// };

export const convertBase64ToString = (base64Str): string => {
  let buff = new Buffer(base64Str, 'base64');
  return buff.toString('ascii');
};

export const getStyledDate = (date: Date) => {
  return moment(date).format('MM/DD/YYYY');
};

export const addZeroSuffix = (n) => {
  return n.length < 2 ? `0${n}` : n;
};

export const getEnumKeyByEnumValue = (myEnum, value) => {
  let keys = Object.keys(myEnum).filter((v) =>
    myEnum[v].localeCompare(value, undefined, { sensitive: 'case' }),
  );
  return keys.length > 0 ? keys[0] : null;
};

interface IsToShowLoadMoreButtonProps {
  listObj: {
    edges: any[];
    pageInfo: {
      hasNextPage: boolean;
    };
    totalCount?: number;
    __typename?: string;
  };
  isLoading: boolean;
  itemsPerPage: number;
}

export const isToShowLoadMoreButton = ({
  isLoading,
  itemsPerPage,
  listObj,
}: IsToShowLoadMoreButtonProps) => {
  return (
    listObj?.pageInfo?.hasNextPage &&
    listObj?.totalCount >
      (isLoading
        ? listObj?.edges?.length + itemsPerPage
        : listObj?.edges?.length)
  );
};
