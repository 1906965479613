import { useEffect, useState } from 'react';
import { useAccountDelete, useAuth } from 'teddly-sdk';
import AccountDeleteDialog from '@components/Organisms/AccountDeleteDialog';
import { useCartContext } from '@context/cart/cart-provider';
import { useNetworkState } from '@hooks/useNetworkState';
import { useRoutes } from '@utils/routes';

import { PageDialog, usePageLayout } from '../context';

export default function DeleteAccountFormController() {
  const { closeDialog, setLoading , isLoading} = usePageLayout();
  const { router, routes, pages } = useRoutes();
  const { email, token, open_dialog } = router.query;
  const [text, setText] = useState('Deleting your Account...');
  const { emptyCartState } = useCartContext();
  const { setIdle } = useNetworkState();
  const { signOut } = useAuth();

  const [accountDelete] = useAccountDelete();
  useEffect(() => {
    if (open_dialog === PageDialog.deleteAccount) {
      setLoading(true);
      handleAccountDelete()
        .catch(() => console.error('Something went wrong!'))
        .finally(() => setLoading(false));
    }
  }, [email]);

  const handleAccountDelete = async () => {
    if (email && token) {
      try {
        await signOut();
        emptyCartState();
        const res = await accountDelete({ email, token });
        setIdle();
        if (!res) {
          setText('Invalid or expired token...');
        } else if (res) {
          setText('The Account wes deleted!');
        }
      } catch (e) {
        setText('Something went wrong!');
      }
    }
  };

  return (
    <>
      <AccountDeleteDialog
        text={text}
        loading={isLoading}
        isOpen={open_dialog === PageDialog.deleteAccount}
        accountDelete={handleAccountDelete}
        onClose={closeDialog}
      />
    </>
  );
}
