const index = {
  account: {
    new: 'account/new.json',
    existing: 'account/existing.json',
    nonExisting: 'account/non-existing.json',
    invalid: 'account/invalid.json',
  },

  loginStates: {
    emptyCart: 'login-states/empty-cart.json',
    emptyOrders: 'login-states/empty-orders.json',
    addressUser: 'login-states/address-user.json',
  },
  
  deliveryAddress: {
    easternParkway: 'delivery-address/eastern-parkway.json',
  },
 
  paymentsMethods: {
    valid: 'payments-methods/valid.json',
  },
};

export default index;
