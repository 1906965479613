import { createSaleorLinks, SaleorProvider } from 'teddly-sdk';

import { apolloConfigForSdk, teddlySdkConfig } from '.';

export function TeddlySdkProvider({ children }: { children: React.ReactNode }) {
  return (
    <SaleorProvider config={teddlySdkConfig} apolloConfig={apolloConfigForSdk}>
      {children}
    </SaleorProvider>
  );
}
