import {
  useContext,
  createContext,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { CurrentOpenDialog } from '../../context';
import { useCheckoutFlow } from '../../context/checkout-flow';
import {
  AddressFragment,
  CheckoutFragment,
  CountryCode,
  useAuth
} from 'teddly-sdk';
import {
  ICheckoutModel,
  ICheckoutAddress
} from 'teddly-sdk/lib/helpers';
import { UserAddressType, useUserAddresses } from '@app/user/hooks';
import { useChannelContext } from '@context/channel/ChannelContext';
import { usePageLayout } from '@components/Content/PageLayout/context';

export const CheckoutShippingAddressContext = createContext<{
  availableShippingMethods: ICheckoutModel['availableShippingMethods'];
  setSelectedShippingMethod: (
    shippingMethod: ICheckoutModel['availableShippingMethods'][0],
  ) => void;
  selectedShippingAddress: ICheckoutModel['shippingAddress'];
  selectedShippingMethod: ICheckoutModel['shippingMethod'];
  openPickAddressForm: () => void;
  saveShippingStatus: 'idle' | 'loading' | 'completed' | 'error';
  closeCurrentDialog: () => void;
  currentOpenDialog: CurrentOpenDialog;
  saveShippingError: string;
  shippingMethodError;
  openAddAddressForm: () => void;
  shippingMethodStatus;
  isCheckoutShippingAddressValid: boolean;
  openEditAddressForm: (addressToEdit: UserAddressType) => void;
  setSelectedShippingAddress: (address: any) => void;
  isAddAddressDialogOpen: boolean;
  isPickAddressDialogOpen: boolean;
  isAddressDialogOpen: boolean;
  addressToEdit?: UserAddressType;
  isShippingLoading: boolean;
  setIsShippingLoading: (isLoading: boolean) => void;
  resetShippingAddress: () => void;
  isValidationShippingAddressField: (field: string) => boolean;
}>(null);

export function CheckoutShippingAddressProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [shippingMethodStatus, setShippingMethodStatus] = useState();
  const { setShowSplashScreen } = usePageLayout();
  const { userAddresses, defaultBillingAddress, defaultShippingAddress } =
    useUserAddresses();
  const [shippingMethodError, setShippingMethodError] = useState();
  const [isCheckoutShippingAddressValid, setIsCheckoutShippingAddressValid] =
    useState<boolean>(false);
  const [isShippingLoading, setIsShippingLoading] = useState(false);
  const [currentOpenDialog, setCurrentOpenDialog] =
    useState<CurrentOpenDialog>(null);

  const [selectedShippingAddress, setSelectedShippingAddress] =
    useState<ICheckoutModel['shippingAddress']>(null);
  const { checkout, setShippingMethod, setShippingAddress } = useCheckoutFlow();
  const { selectedChannel } = useChannelContext();
  const { user } = useAuth();
  const [addressToEdit, setAddressToEdit] = useState<UserAddressType>(null);
  const userId = user ? user.id : null;
  const [saveShippingStatus, setSaveShippingStatus] = useState<any>();
  const [saveShippingError, setSaveShippingError] = useState('');

  const changeSelectedShippingAddress = (address: ICheckoutAddress) => {
    const isChannelChanged = address?.channel?.id !== selectedChannel?.id;
    setSelectedShippingAddress(address);
    if (isChannelChanged) {
      setShowSplashScreen(true);
    }
  };
  const resetShipping = () => {
    setSelectedShippingAddress(null);
    setSaveShippingStatus('idle');
    setIsCheckoutShippingAddressValid(false);
  };
  useEffect(() => {
    if (!userId) {
      resetShipping();
    }
  }, [userId]);

  useEffect(() => {
    if (!selectedShippingAddress) {
      if (
        checkout?.shippingAddress &&
        userAddresses &&
        userAddresses.length > 0 &&
        userAddresses?.find((add) => add?.id === checkout?.shippingAddress?.id)
      ) {
        setSelectedShippingAddress(checkout?.shippingAddress);
      } else if (
        checkout &&
        defaultShippingAddress &&
        defaultShippingAddress?.channel?.id === selectedChannel?.id
      ) {
        setSelectedShippingAddress(
          defaultShippingAddress
            ? userAddresses.find(
                (a) =>
                  a.id === defaultShippingAddress.id && a?.isValidToShipping,
              )
            : userAddresses.find((a) => a?.isValidToShipping),
        );
      }
    }
  }, [userAddresses, checkout?.id]);

  const isValidationShippingAddressField = (field: string) => {
    if (!selectedShippingAddress[field]) {
      return false;
    }
    return true;
  };
  const setDefaultShippingMethod = () => {
    if (
      !checkout?.availableShippingMethods ||
      checkout?.availableShippingMethods.length == 0
    )
      return;
    if (checkout?.availableShippingMethods.length === 1) {
      setSelectedShippingMethod(checkout?.availableShippingMethods[0]);
    } else {
      const regularMethods: CheckoutFragment['availableShippingMethods'] =
        checkout?.availableShippingMethods.filter(
          (v, index) => index < checkout?.availableShippingMethods.length - 1,
        );
      if (regularMethods.find((v) => v.id == checkout?.shippingMethod?.id)) {
        setSelectedShippingMethod(
          regularMethods.find(
            (v) => v.isFastDelivery == checkout?.shippingMethod.isFastDelivery,
          ),
        );
      } else {
        setSelectedShippingMethod(regularMethods[0]);
      }
    }
  };
  useEffect(() => {
    if (
      userId &&
      checkout?.id &&
      selectedShippingAddress &&
      selectedShippingAddress?.id !== checkout?.shippingAddress?.id
    ) {
      runSetShippingAddress();
    }
  }, [checkout?.id, selectedShippingAddress, userId]);
  useEffect(() => {
    setDefaultShippingMethod();
  }, [checkout?.availableShippingMethods]);
  const runSetShippingAddress = () => {
    setIsShippingLoading(true);
    const currentSelectedAddress = selectedShippingAddress;
    const shippingAddress: Omit<AddressFragment, 'channel'> = {
      city: currentSelectedAddress?.city,
      country: {
        code: CountryCode.US,
        country: 'United States of America',
      },
      companyName: currentSelectedAddress?.companyName,
      countryArea: currentSelectedAddress?.countryArea,
      firstName: currentSelectedAddress?.firstName,
      id: currentSelectedAddress?.id,
      lastName: currentSelectedAddress?.lastName,
      phone: currentSelectedAddress?.phone,
      postalCode: currentSelectedAddress?.postalCode,
      streetAddress1: currentSelectedAddress?.streetAddress1,
      streetAddress2: currentSelectedAddress?.streetAddress2,
      note: currentSelectedAddress?.note,
      tag: currentSelectedAddress?.tag,
    };

    setShippingAddress(shippingAddress as AddressFragment, user.email)
      .then(async ({ dataError, functionError, data }) => {
        // if(data?.)
        if (dataError || functionError) {
          setSaveShippingError(
            dataError?.error ||
              functionError?.error ||
              'Something went wrong while saving the shipping address',
          );
        } else {
          // const requestAddressChannel = selectedShippingAddress?.channel?.id
          // const responseAddressChannel = data?.shippingAddress?.channel?.id
          // debugger
          // if(requestAddressChannel && requestAddressChannel == responseAddressChannel && requestAddressChannel !== selectedChannel?.id ){
          // }
        }
      })
      .catch((e) => {
        console.error('console.error in catch', e);
        setSaveShippingError(e.toString());
      })
      .finally(() => {
        setIsShippingLoading(false);
      });
  };
  const setSelectedShippingMethod = (
    shippingMethod: CheckoutFragment['shippingMethod'],
  ) => {
    if (shippingMethod.id == checkout?.shippingMethod?.id) {
      setIsCheckoutShippingAddressValid(true);
      return;
    }
    setIsShippingLoading(true);
    setShippingMethod(shippingMethod?.id)
      .then(({ dataError, functionError, data }) => {
        if (dataError || functionError) {
          setShippingMethodError(
            dataError?.error ||
              functionError?.error ||
              'Something went wrong setting shipping method',
          );
        } else {
          setSaveShippingStatus('complete');
          setIsCheckoutShippingAddressValid(true);
        }
      })
      .catch((e) => {
        console.error(e);
        setShippingMethodError(e.toString());
      })
      .finally(() => {
        setIsShippingLoading(false);
      });
  };

  const closeCurrentDialog = () => setCurrentOpenDialog(CurrentOpenDialog.None);

  const isAddAddressDialogOpen =
    currentOpenDialog === CurrentOpenDialog.AddAddressForm;
  const isPickAddressDialogOpen =
    currentOpenDialog === CurrentOpenDialog.PickAddress;
  const isAddressDialogOpen = isAddAddressDialogOpen || isPickAddressDialogOpen;

  const openAddAddressForm = () =>
    setCurrentOpenDialog(CurrentOpenDialog.AddAddressForm);

  const openPickAddressForm = () =>
    setCurrentOpenDialog(CurrentOpenDialog.PickAddress);
  const openEditAddressForm = (address: UserAddressType) => {
    setCurrentOpenDialog(CurrentOpenDialog.EditAddressForm);
    setAddressToEdit(address);
  };

  return (
    <CheckoutShippingAddressContext.Provider
      value={{
        availableShippingMethods: checkout?.availableShippingMethods,
        setSelectedShippingMethod: setSelectedShippingMethod,
        openEditAddressForm: openEditAddressForm,
        setSelectedShippingAddress: changeSelectedShippingAddress,
        selectedShippingAddress: selectedShippingAddress,
        selectedShippingMethod: checkout?.shippingMethod,
        openPickAddressForm: openPickAddressForm,
        saveShippingStatus: saveShippingStatus,
        closeCurrentDialog: closeCurrentDialog,
        currentOpenDialog: currentOpenDialog,
        saveShippingError: saveShippingError,
        shippingMethodError: shippingMethodError,
        openAddAddressForm: openAddAddressForm,
        shippingMethodStatus: shippingMethodStatus,
        isCheckoutShippingAddressValid: isCheckoutShippingAddressValid,
        isAddAddressDialogOpen: isAddAddressDialogOpen,
        isPickAddressDialogOpen: isPickAddressDialogOpen,
        isAddressDialogOpen: isAddressDialogOpen,
        isShippingLoading: isShippingLoading,
        setIsShippingLoading: setIsShippingLoading,
        addressToEdit: addressToEdit,
        resetShippingAddress: resetShipping,
        isValidationShippingAddressField: isValidationShippingAddressField,
      }}
    >
      {children}
    </CheckoutShippingAddressContext.Provider>
  );
}

export const useCheckoutShippingAddressContext = () => {
  const context = useContext(CheckoutShippingAddressContext);

  if (!context)
    throw new Error(
      'useUserAddresses should be used within CheckoutShippingAddressContext',
    );

  return context;
};
