/**
 * TODO:
 * 1. create data-testids map (ids)
 * 2. Create getter for all of the required elements
 * 3. Create basic actions - click (button) input (type/select) assert (varifying text fields)
 */

import Utils from '../../utils/utils.cy';

export const ids = {
  addItemsButton: 'add-items-button', ///
  allItemsTab: 'all-items-tab', ///
  fastDeliveryTab: 'fast-delivery-tab', ///
  closeButton: 'close-button',
  itemDetails: 'item-details',
  closeOrderSummaryButton: 'close-order-summary-button',
  orderSummaryButton: 'order-summary-button', //.
  checkoutButton: 'checkout-button', ///
  categoriesItems: 'categpries-items', ///
  itemDetailsPlus: 'counter-plus-button', ///
  itemDetailsMinusNumber: 'counter-minus-button', ///
  fastDeliveryGroceryButton: 'fast-delivery-grocery-button', ///
};

export default class CartPage {
  get fastDeliveryGroceryButton() {
    return cy.get(
      Utils.formatDataTestIdSelector(ids.fastDeliveryGroceryButton)
    );
  }

  get allItemsTab() {
    return cy.get(Utils.formatDataTestIdSelector(ids.allItemsTab));
  }

  get fastDeliveryTab() {
    return cy.get(Utils.formatDataTestIdSelector(ids.fastDeliveryTab));
  }
  get categoriesItems() {
    return cy.get(Utils.formatDataTestIdSelector(ids.categoriesItems));
  }

  get closeButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.closeButton));
  }
  get orderSummaryButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.orderSummaryButton));
  }

  get checkoutButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.checkoutButton));
  }
  get addItemsButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.addItemsButton));
  }

  get itemDetailsPlus() {
    return cy.get(Utils.formatDataTestIdSelector(ids.itemDetailsPlus));
  }

  get itemDetailsMinus() {
    return cy.get(Utils.formatDataTestIdSelector(ids.itemDetailsMinusNumber));
  }
  get delivery() {
    return cy.get(`[data-testid="delivery-value"]`);
  }
  get tax() {
    return cy.get(`[data-testid="tax-value"]`);
  }
  get tip() {
    return cy.get(`[data-testid="tip-value"]`);
  }
  get giftCard() {
    return cy.get(`[data-testid="giftcard-value"]`);
  }
  get total() {
    return cy.get(`[data-testid="total-value"]`);
  }
  get subTotal() {
    return cy.get(`[data-testid="subtotal-value"]`);
  }

  clickItemDetailsMinusNumber0() {
    cy.get('[data-testid="item-details-minus-number-0"]', { timeout: 5000 })
      .should('be.visible')
      .click({ force: true, multiple: true });
  }

  clickFastDeliveryGroceryButton() {
    return this.fastDeliveryGroceryButton.click({
      force: true,
      multiple: true,
    });
  }

  clickAllItemsTab() {
    return this.allItemsTab.click({ force: true, multiple: true });
  }

  clickFastDeliveryTab() {
    return this.fastDeliveryTab.click({ force: true, multiple: true });
  }

  clickCloseButton() {
    return this.closeButton.click({ force: true });
  }
  clickOrderSummaryButton() {
    return this.orderSummaryButton.click({ force: true });
  }
  clickCheckoutButton() {
    return this.checkoutButton.click({ force: true });
  }
  clickAddItemsButton() {
    return this.addItemsButton.click({ force: true });
  }
  clickCategoriesItemsArrowDownButton() {
    return this.categoriesItems.find('button').click({ force: true });
  }

  alertMessage(message) {
    cy.on('window:alert', (message) => {
      cy.log('Developer Alert: ' + message);
    });

    // Alert the developer to clear the cart
    cy.window().then((win) => {
      win.alert(message);
    });
  }
}
