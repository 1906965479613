import { Dialog, Button, InputBase, Counter } from '@teddly/teddly-ui-components'
import { useState } from 'react'
import { ids } from '../../../../../cypress/pages/shopping-lists/MyShoppingListsPage.cy'

type CreateListDialogProps = {
    isOpen: boolean;
    closeDialog: () => void;
    submit: (listName: string, quantity?: number) => void;
    listNameForEdit?: string
    isFromItemDescription?: boolean;
    loading?: boolean;
};

export default function CreateListDialog({
    isOpen,
    closeDialog,
    submit,
    listNameForEdit,
    loading,
    isFromItemDescription
}: CreateListDialogProps) {

    const [quantity, setQuantity] = useState<number>(1)

    const [listName, setListName] = useState<string>(listNameForEdit ? listNameForEdit : "")

    let isValid: boolean = listName?.length > 2 && listName?.length < 30;

    function onSaveClick() {
        if (isFromItemDescription) {
            submit(listName, quantity);
            setQuantity(1)
        }
        else {
            submit(listName);
            setListName('')
        }
    }

    return (
        <Dialog
            key={"1"}
            isOpen={isOpen}
            onClose={closeDialog}
            isWithTopMargin
            footerProps={{
                children: [
                    <Button
                        key={"2"}
                        data-testid={ids.saveListButton}
                        disabled={!isValid}
                        onClick={onSaveClick}
                        title={`Save`}
                        loading={loading}
                    />,
                ],
            }}
            title={listNameForEdit ? 'Edit List Name' : 'New Shopping List'}
        >
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <InputBase
                    data-testid={ids.shoppingListNameInput}
                    name="shoppingListName"
                    label="Shopping List Name"
                    onChange={(value) => setListName(value.target.value)}
                    message={'failed to add list'}
                    defaultValue={listNameForEdit ? listNameForEdit : ''}
                />
                {isFromItemDescription && !listNameForEdit &&
                    <div style={{ display: "flex", gap: "16px", marginTop: "16px", alignItems: "center", width: "100%" }}>
                        Quantity:
                        <Counter
                            value={quantity}
                            disabled={!isFromItemDescription}
                            onPlus={() => setQuantity(quantity + 1)}
                            onMinus={() => setQuantity(quantity - 1)}
                        />
                    </div>
                }
            </div>
        </Dialog>
    )
}

