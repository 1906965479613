import { VendorDetails } from '@interfaces';
import { useRouter } from 'next/router';
import {
  useContext,
  createContext,
  ReactNode,
  useState,
} from 'react';

export const VendorContext = createContext<{
  vendor: VendorDetails;
  setVendor: (vendor: VendorDetails) => void;
}>(null);

export function VendorProvider({ children }: { children: ReactNode }) {
  const { vendorSlug } = useRouter().query;
  const [vendor, setVendor] = useState<VendorDetails>(null);

  return (
    <VendorContext.Provider
      value={{
        vendor:vendorSlug? vendor: null,
        setVendor: setVendor,
      }}
    >
      {children}
    </VendorContext.Provider>
  );
}

export const useVendor = () => {
  const context = useContext(VendorContext);

  if (!context)
    throw new Error('useUserAddresses should be used within VendorContext');

  return context;
};
