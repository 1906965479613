import { useCartContext } from '@context/cart/cart-provider';
import { Collapsible } from '@teddly/teddly-ui-components';
import Coupon from '../Coupon';
import GiftCard from '../GiftCard';
import { RowWithValue } from './RowWithValue';
import { ids } from '../../../../../../cypress/pages/checkout/CheckoutPage.cy'

type propsType = {};

enum titles {
  SUMMARY = 'Order Summary',
  SHIPPING_AND_RETURNS = 'our return policy',
  NTK = 'Need to know',
  COUPON = 'Add coupon ',
  GIFTCARD = 'Add gift card ',
}

export function CheckoutInfo({ }: propsType) {
  const {
    additionalAmount,
    discountPrice,
    shippingPrice,
    subTotalPrice,
    taxPrice,
    total,
    tip,
    giftCards,
    isCalculatePrice,
  } = useCartContext();

  return (
    <>
      <Collapsible data-testid={ids.orderSummeryCollapsible} title={titles.SUMMARY} initExpand={true}>
        <RowWithValue dataTestId={ids.subTotal} label={'Sub Total'} value={subTotalPrice} />
        <RowWithValue dataTestId={ids.tax} label={'Tax'} value={taxPrice} />
        <RowWithValue dataTestId={ids.delivery} label={'Delivery'} value={shippingPrice} />
        <RowWithValue dataTestId={ids.tip} label={'Tip'} value={tip} show={tip > 0} />
        <RowWithValue
          dataTestId={ids.giftCard}
          minus
          label={'Gift Card'}
          value={giftCards?.giftCardsUsageAmount?.amount}
          show={giftCards?.cards?.length > 0}
        />
        <RowWithValue
          dataTestId={ids.coupon}
          minus
          label={'Discount'}
          value={discountPrice}
          show={discountPrice > 0}
        />
        <RowWithValue dataTestId={ids.total} label={'Total'} value={total} show />
      </Collapsible>
      <Collapsible data-testid={ids.addCouponCollapsible} title={titles.COUPON}>
        <Coupon/>
      </Collapsible>
      <Collapsible data-testid={ids.addGiftCardCollapsible} title={titles.GIFTCARD}>
        <GiftCard />
      </Collapsible>
      <Collapsible data-testid={ids.ourReturnPolicyCollapsible} title={titles.SHIPPING_AND_RETURNS}>
        <div>
          <p>
            We hope you love your items, but in case you don’t here’s what we
            can do:
          </p>
          <br />
          <p>
            Grocery items can be returned within 7 days of purchase for a
            refund. We unfortunately cannot guarantee a refund after 7 days of
            purchase.
          </p>
          <br />
          <p>For non-grocery items, we will honor refunds for up to 30 days.</p>
          <br />
          <p>Shipping expenses are non-refundable.</p>
        </div>
      </Collapsible>
    </>
  );
}
