import { NewVariant, CartableProduct, VendorDetails } from '@interfaces';
import { convertBase64ToString } from '@utils';
import { OrderById_orderById_lines } from 'teddly-sdk/lib/queries/gqlTypes/OrderById';

export type VendorsProducts = { [key: string]: any[] }

export const sortProductsByVendors = (variants: NewVariant[]) => {
  const vendors = {};
  variants.forEach((item) => {
    if (!item.vendor) return;
    if (vendors[item.vendor?.name]) {
      vendors[item.vendor.name].push(item);
    } else {
      vendors[item.vendor.name] = [item];
    }
  });
  return vendors;
};

export const linesToCartableProducts = (
  lines: OrderById_orderById_lines[]
): NewVariant[] => {
  lines = lines.filter((line) => line?.variant);
  return lines.map((line) => {
    const variant = line?.variant;
    const vendor = variant.vendor;
    return {
      orderLineId:line.id,
      id: variant.id,
      product_id: variant?.product?.id,
      name: `${line?.productName} ${variant.name != '-' ? variant.name : ''}`,
      thumbnail: variant?.product?.thumbnail?.url,
      images: [variant?.product?.thumbnail?.url],
      in_stock: !line?.isProductMissing,
      // in_stock: line?.variant?.isAvailableForPurchase && line?.variant?.isInStock,
      vendor: {
        slug: vendor.slug,
        name: vendor.name,
        logo: vendor.logoUrl,
      } as VendorDetails,
      price: variant?.pricing?.price?.net?.amount,
      line: {
        quantity: line?.quantity,
        note: '',
        isInclude:(line as any).isInclude,
        is_missing: line.isProductMissing,
        price: line?.totalPrice?.net?.amount, // Gross ? Net
      },
    } as unknown as NewVariant;
  });
};
