import React, { useState } from 'react';
import { useCheckoutFlow } from '../../context/checkout-flow';
import { DetailHeading } from '../styled';
import { css } from '@emotion/react';
import { Switch } from '@teddly/teddly-ui-components';
type Props = {
  setIsLoading: (isLoading: boolean) => void;
  itemSubstitutionDataTestId: string;
  toivelingDataTestId: string;
  specialRequestSectionDataTestId: string;
};
export default function SpecialRequest({ setIsLoading, specialRequestSectionDataTestId, itemSubstitutionDataTestId, toivelingDataTestId }: Props) {
  const { checkout, updateCheckout } = useCheckoutFlow();

  const itemSubstitutionChangeHandler = async () => {
    setIsLoading(true);
    await updateCheckout({checkoutId: checkout?.id, itemSubstitution:!checkout?.itemSubstitution});
    setIsLoading(false);
  };

  const toivelingChangeHandler = async () => {
    setIsLoading(true);
    await updateCheckout({checkoutId: checkout?.id, toivelingItems: !checkout?.toivelingItems});
    setIsLoading(false);
  };

  const toivelingDescription = `Toiveling Options *

  There are some items in your cart which may require Toiveling - whould you like them Toiveled?
  If you choose to Toivel a product, it cannot be returned.
  Unfortunately, we cannot Toivel items which contain electrical components.`;

  return (
    <div>
      <DetailHeading data-testid={specialRequestSectionDataTestId}>Special requests</DetailHeading>
      <div
        css={[
          css`
            display: flex;
            flex-direction: row;
            gap: 16px;
          `,
        ]}
      >
        <div>
          <Switch
            dsta-testid={itemSubstitutionDataTestId}
            dataTestId={itemSubstitutionDataTestId}
            id="itemSubstitution"
            label="Item Substitution?"
            checked={checkout?.itemSubstitution}
            onChange={itemSubstitutionChangeHandler}
          />
        </div>
        {checkout?.isToiveling ? (
          <div>
            <Switch
              data-testid={toivelingDataTestId}
              dataTestId={toivelingDataTestId}
              id="toiveling"
              checked={checkout?.toivelingItems}
              onChange={toivelingChangeHandler}
              label="Toiveling?"
            ></Switch>
          </div>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
}
