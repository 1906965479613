import React, { useContext, useReducer, useEffect, useState } from 'react';

import router, { useRouter } from 'next/router';

import useSafeDispatch from '@hooks/useSafeDispatch';
import { useAuth } from 'teddly-sdk';
import * as Sentry from '@sentry/react';
import { NewProduct, NewVariant, VendorDetails } from '@interfaces';
import { Snackbar } from '@teddly/teddly-ui-components';
import { LineSelected } from '@components/Pages/ShoppingListDetailsPage/types';
import { scrollToTheTopOfTheElement } from '@hooks/useWindowDimension';
import { ProductsUserDataType } from '@app/products/ProductListWidget';
import { NavigationProps, useRoutes } from '@utils/routes';
import { getServerAssetsImageUrl } from '@utils';
import { getRootCategoriesBaseFragments } from '@services/categories/api';
import { useChannelContext } from '@context/channel/ChannelContext';

export enum SnackbarVariant {
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export enum PageDialog {
  none = '',
  login = 'login',
  code = 'code',
  signup = 'signup',
  forgotPassword = 'forgotPassword',
  resetPassword = 'resetPassword',
  deleteAccount = 'deleteAccount',
  editAccount = 'editAccount',
  product = 'product',
  order = 'order',
  cartConflict = 'cartConflict'
}

interface ISnackbarProps {
  variant: SnackbarVariant; // The known field with a specific type
  [customName: string]: any; // Index signature for custom fields with any type
}

export type PageLayoutState = {
  showSplashScreen: boolean;
  currentVariant: NewVariant;
  disabledCounter: boolean;
  line: LineSelected;
  open_dialog: PageDialog;
  queryId: string;
  resultsIndexName: string;
  currentProduct: NewProduct;
  snackbars: Snackbar[];
  isCartOpen: boolean;
  isLoading: boolean;
  showOrderAgainButton: boolean;
  isSidebarNavOpen: boolean;
  isPageSidebarNavOpen: boolean;
  accountSettingData: any;
  dispatch: any;
  addSnackbar: (snackbar: ISnackbarProps) => void;
  closeSnackbar: (id: string) => void;
};

export type PageLayoutActions =
  | {
      type: 'OPEN_DIALOG';
      dialog: PageDialog;
    }
  | {
      type: 'SHOW_SPLASH_SCREEN';
      value: boolean;
    }
  | {
      type: 'OPEN_PRODUCT_DIALOG';
      dialog: PageDialog.product;
      product_id: string;
      product?: NewProduct;
      line?: LineSelected;
      disabledCounter?: boolean;
      currentVariant?: NewVariant;
      queryId?: string;
      resultsIndexName?: string;
    }
  | {
      type: 'CLOSE_DIALOG';
      navigateToLastPath: boolean;
    }
  | {
      type: 'LOADING';
      value: boolean;
    }
  | {
      type: 'ORDER_AGAIN';
      value: boolean;
    }
  | {
      type: 'TOGGLE_CART';
      value: boolean;
    }
  | {
      type: 'OPEN_SIDEBAR_NAV';
    }
  | {
      type: 'CLOSE_SIDEBAR_NAV';
    }
  | {
      type: 'CLOSE_PAGE_SIDEBAR_NAV';
    }
  | {
      type: 'ACCOUNT_SETTING';
      accountSetting: string;
    };

export const initialPageLayoutState: PageLayoutState = {
  currentVariant: null,
  disabledCounter: null,
  line: null,
  open_dialog: PageDialog.none,
  addSnackbar: (v) => undefined,
  closeSnackbar: (v) => undefined,
  snackbars: [],
  isCartOpen: false,
  currentProduct: null,
  accountSettingData: '',
  isLoading: false,
  showOrderAgainButton: true,
  isSidebarNavOpen: null,
  isPageSidebarNavOpen: false,
  dispatch: () => {},
  queryId: '',
  resultsIndexName: '',
  showSplashScreen: true,
};

export const PageLayoutContext = React.createContext<
  PageLayoutState & {
    dispatch?: React.Dispatch<PageLayoutActions>;
    openLoginDialog?: () => void;
    openCartConflictDialog?: () => void;
    accountSetting?: (data) => void;
    openSignUpDialog?: () => void;
    openForgotPasswordDialog?: () => void;
    openOrderDetailsDialog?: () => void;
    openResetPasswordDialog?: () => void;
    openDeleteAccountDialog?: () => void;
    openEditAccountDialog?: () => void;
    openProductDialog?: ({}: {
      product_id: string;
      product?: NewProduct;
      line?: LineSelected;
      disabledCounter?: boolean;
      currentVariant?: NewVariant;
      queryId?: string;
      resultsIndexName?: string;
    }) => void;
    closeDialog?: (navigateToLastPath?: boolean) => void;
    setLoading?: (value: boolean) => void;
    setShowOrderAgainButton?: (value: boolean) => void;
    openSidebarNav?: (e) => void;
    closeSidebarNav?: (e) => void;
    closePageSidebarNav?: (e) => void;
    setShowSplashScreen?: (v: boolean) => void;
    setTabularDataPageLoading?: ({
      dataLoading,
      tabularData,
      totalCount,
    }: {
      dataLoading: Boolean;
      totalCount: number;
      tabularData: {};
    }) => void;
  }
>(initialPageLayoutState);

export function usePageLayout() {
  const context = useContext(PageLayoutContext);
  if (!context)
    throw new Error('usePageLayout must be used within PageLayoutContext');
  return context;
}

export const pageLayoutReducer: React.Reducer<
  PageLayoutState,
  PageLayoutActions
> = (state: PageLayoutState, action: PageLayoutActions): PageLayoutState => {
  switch (action.type) {
    case 'OPEN_DIALOG': {
      const newQuery = { ...router.query, open_dialog: action.dialog };
      router.push(
        {
          pathname: router?.pathname || '',
          query: newQuery,
        },
        undefined,
        { scroll: false },
      );

      if (PageDialog[action.dialog]) {
        return {
          ...state,
          isCartOpen: false,
          open_dialog: action.dialog,
        };
      }
      return state;
    }

    case 'OPEN_PRODUCT_DIALOG': {
      const newQuery = {
        ...router.query,
        open_dialog: action.dialog,
        // TODO: Add back when needed
        product: action.product_id,
      };
      router.push(
        {
          pathname: router?.pathname || '',
          query: newQuery,
        },
        undefined,
        { scroll: true },
      );
      scrollToTheTopOfTheElement('dialog-body');
      if (PageDialog[action.dialog]) {
        return {
          ...state,
          open_dialog: action.dialog,
          currentProduct: action.product,
          line: action.line,
          currentVariant: action.currentVariant,
          disabledCounter: action.disabledCounter,
          queryId: action.queryId,
          resultsIndexName: action.resultsIndexName,
        };
      }
      return state;
    }

    case 'CLOSE_DIALOG': {
      
      const newQuery = router.query;

      if (router.query.open_dialog === PageDialog.product) {
        delete newQuery.product;
      }
      delete newQuery.open_dialog;
      delete newQuery.apple_code;
      delete newQuery.code;
      delete newQuery.first_name;
      delete newQuery.last_name;

      if (action.navigateToLastPath) {
        // router.back();
        router.push(
          {
            pathname: router?.pathname || '',
            query: newQuery,
          },
          '',
          { scroll: false },
        );
      }
      return {
        ...state,
        open_dialog: PageDialog.none,
        line: null,
        disabledCounter: null,
        currentVariant: null,
        currentProduct: null
      };
    }
    case 'LOADING': {
      const { value } = action;
      return {
        ...state,
        isLoading: value,
      };
    }
    case 'SHOW_SPLASH_SCREEN': {
      const { value } = action;
      return {
        ...state,
        showSplashScreen: value,
      };
    }
    case 'ORDER_AGAIN': {
      const { value } = action;
      return {
        ...state,
        showOrderAgainButton: value,
      };
    }
    case 'TOGGLE_CART':
      const { value } = action;
      return {
        ...state,
        isCartOpen: value,
      };

    case 'OPEN_SIDEBAR_NAV':
      return {
        ...state,
        isSidebarNavOpen: true,
        isPageSidebarNavOpen: true,
      };

    case 'CLOSE_SIDEBAR_NAV':
      return {
        ...state,
        isSidebarNavOpen: false,
      };

    case 'CLOSE_PAGE_SIDEBAR_NAV':
      return {
        ...state,
        isPageSidebarNavOpen: false,
      };

    case 'ACCOUNT_SETTING':
      return {
        ...state,
        accountSettingData: action.accountSetting,
      };

    default:
      return state;
  }
};

export const PageLayoutContextProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(pageLayoutReducer, {
    ...initialPageLayoutState,
  });
  const { query } = useRouter();
  const { user } = useAuth();
  const [snackbars, setSnackbars] = useState([]);
  const { isCurrentPage, pages, navigateTo } = useRoutes();

  const safeDispatch: React.Dispatch<PageLayoutActions> =
    useSafeDispatch(dispatch);

  const { open_dialog, apple_code, code } = query;
  //update sentry with user()
  useEffect(() => {
    if (user) {
      Sentry.setUser({ id: user?.id, email: user?.email });
    } else {
      Sentry.setUser(null);
    }
  }, [user]);

  useEffect(() => {
    if (apple_code || code) {
      safeDispatch({
        type: 'OPEN_DIALOG',
        dialog: PageDialog.login,
      });
    } else if (open_dialog && PageDialog[open_dialog.toString()]) {
      safeDispatch({
        type: 'OPEN_DIALOG',
        dialog: open_dialog as PageDialog,
      });
    }
  }, [apple_code, code, open_dialog, safeDispatch]);

  const openLoginDialog = () =>
    safeDispatch({
      type: 'OPEN_DIALOG',
      dialog: PageDialog.login,
    });

    const openCartConflictDialog = () =>
      safeDispatch({
        type: 'OPEN_DIALOG',
        dialog: PageDialog.cartConflict,
      });
  

  const accountSetting = (data) =>
    safeDispatch({
      type: 'ACCOUNT_SETTING',
      accountSetting: data,
    });

  const openSignUpDialog = () =>
    safeDispatch({
      type: 'OPEN_DIALOG',
      dialog: PageDialog.signup,
    });

  const openResetPasswordDialog = () =>
    safeDispatch({
      type: 'OPEN_DIALOG',
      dialog: PageDialog.resetPassword,
    });
  const openDeleteAccountDialog = () =>
    safeDispatch({
      type: 'OPEN_DIALOG',
      dialog: PageDialog.deleteAccount,
    });

  const openForgotPasswordDialog = () =>
    safeDispatch({
      type: 'OPEN_DIALOG',
      dialog: PageDialog.forgotPassword,
    });
  const openEditAccountDialog = () =>
    safeDispatch({
      type: 'OPEN_DIALOG',
      dialog: PageDialog.editAccount,
    });

  const openOrderDetailsDialog = () =>
    safeDispatch({
      type: 'OPEN_DIALOG',
      dialog: PageDialog.order,
    });

  const openProductDialog = ({
    product_id,
    currentVariant,
    disabledCounter,
    line,
    product,
    queryId,
    resultsIndexName,
  }: {
    product_id: string;
    product?: NewProduct;
    line?: LineSelected;
    disabledCounter?: boolean;
    currentVariant?: NewVariant;
    queryId?: string;
    resultsIndexName?: string;
  }) => {
    safeDispatch({
      type: 'OPEN_PRODUCT_DIALOG',
      dialog: PageDialog.product,
      product_id,
      product,
      line,
      disabledCounter,
      currentVariant,
      queryId,
      resultsIndexName,
    });
  };

  const closeDialog = (navigateToLastPath: boolean = true) => {
    safeDispatch({ type: 'CLOSE_DIALOG', navigateToLastPath });
  };
  const setLoading = (value: boolean) =>
    safeDispatch({ type: 'LOADING', value });
  const setShowSplashScreen = (value: boolean) =>
    safeDispatch({ type: 'SHOW_SPLASH_SCREEN', value });
  const setShowOrderAgainButton = (value: boolean) =>
    safeDispatch({ type: 'ORDER_AGAIN', value });

  const openSidebarNav = (e) => {
    e.stopPropagation();
    safeDispatch({ type: 'OPEN_SIDEBAR_NAV' });
  };
  const closeSidebarNav = (e) => {
    safeDispatch({ type: 'CLOSE_SIDEBAR_NAV' });
    const time = 150;
    setTimeout(() => {
      safeDispatch({ type: 'CLOSE_PAGE_SIDEBAR_NAV' });
    }, time);
  };

  const addSnackbar = (snackbarData: ISnackbarProps) => {
    snackbarData.isOpen = true;
    snackbars.push(snackbarData);
    setSnackbars([...snackbars]);
  };
  const closeSnackbar = (id: string) => {
    snackbars.forEach((v) => {
      if (v.id === id) v.isOpen = false;
    });
    setSnackbars([...snackbars]);
  };

  const setTabularDataPageLoading = ({
    dataLoading,
    tabularData,
    totalCount,
  }: {
    dataLoading: Boolean;
    totalCount: number;
    tabularData: {};
  }) => {
    if (dataLoading) {
      setLoading(true);
    } else if (totalCount > 0) {
      setLoading(!tabularData);
    } else {
      setLoading(false);
    }
  };

  return (
    <PageLayoutContext.Provider
      value={{
        ...state,
        dispatch: safeDispatch,
        openLoginDialog,
        accountSetting,
        openSignUpDialog,
        openResetPasswordDialog,
        openDeleteAccountDialog,
        openForgotPasswordDialog,
        openOrderDetailsDialog,
        openProductDialog,
        openEditAccountDialog,
        closeDialog,
        setLoading,
        setShowOrderAgainButton,
        openSidebarNav,
        snackbars,
        addSnackbar,
        closeSnackbar,
        closeSidebarNav,
        setTabularDataPageLoading,
        setShowSplashScreen,
        openCartConflictDialog
      }}
    >
      {children}
    </PageLayoutContext.Provider>
  );
};
