import React, { useEffect, useState } from 'react';

import {
  useRefinementList,
  useInfiniteHits,
  useInstantSearch,
  useClearRefinements,
  useSortBy,
  useConfigure,
  usePagination,
} from 'react-instantsearch-hooks-web';
import FastDeliveryFilterIcon from 'public/assets/90-minutes-filter.svg';
import styles from './ProductListWidget.module.scss';

import {
  CheckoutTypeEnum,
  ProductsUserDataQuery,
  useAuth,
  useProductsUserData,
  useProductsUserDataLazyQuery,
  useProductsUserDataQuery,
} from 'teddly-sdk';
import { usePageLayout } from '@components/Content/PageLayout/context';
import { AlgoliaIndexes, InstantSearchWrapper } from '@config/algolia';
import { useCartContext } from '@context/cart/cart-provider';
import { NewProduct, NewVariant } from '@interfaces';
import {
  ItemDetails,
  InfiniteList,
  Filters,
  Button,
  TitledContent,
} from '@teddly/teddly-ui-components';
import { useVendor } from '@components/Pages/ShopByStore/context';
import { useRoutes } from '@utils/routes';
import { useFastDelivery } from '@components/Pages/FastDelivery/context';
import { ids as cartIds } from '../../../../cypress/pages/cart/CartPage.cy';
import { ids as searchFieldIds } from '../../../../cypress/pages/products/SearchResultsPage.cy';
import { useCheckoutFlow } from '@components/Pages/Checkout/context/checkout-flow';
import classNames from 'classnames';
import { cloneDeep } from 'lodash';
import { ProductsUserData_productsUserData } from 'teddly-sdk/lib/queries/gqlTypes/ProductsUserData';
import { useChannelContext } from '@context/channel/ChannelContext';
import { ProductEvents, sendConvertedEvent } from '@config/algolia/utils';

export type SortProductIndex = {
  id: string;
  index: number;
};

export type ProductsUserDataType = ProductsUserDataQuery['productsUserData'][0];

type Props = {
  searchQuery?: string | string[];
  showFilters?: boolean;
  className?: string;
  fromCollection?: string;
  fromCategory?: string;
  fromVendor?: string;
  fromDepartment?: string;
  children?: React.ReactNode;
  hitsPerPage?: number;
  maxHits?: number;
  isVertical?: boolean;
  clickToLoad?: boolean;
  withBody?: boolean;
  title?: string;
  query?: string;
  scrollableTargetId?: string;
  excludingSku?: string;
  categories?: React.ReactNode;
  search?: React.ReactNode;
  ids?: string[];
  includeOutOfStock?: boolean;
  variantsIds?: string[];
  itemClassName?: string;
  returnAsSimpleList?: boolean;
  sortProductsIndex?: SortProductIndex[];
  waitLoadingForCategories?: boolean;
};
const INITIAL_INDEX = AlgoliaIndexes.PRODUCTS_MOST_POPULAR;
const SHOW_MORE_LIMIT = 40;
const ProductsList = (props: Props) => {
  const {
    showFilters = true,
    hitsPerPage = 20,
    isVertical = false,
    maxHits,
    returnAsSimpleList = false,
    clickToLoad = false,
    fromCollection,
    query,
    waitLoadingForCategories,
    fromCategory,
    fromVendor,
    fromDepartment,
    className,
    scrollableTargetId,
    excludingSku,
    title,
    ids,
    variantsIds,
    includeOutOfStock = true,
    sortProductsIndex,
    itemClassName,
    ...otherProps
  } = props;

  const { user } = useAuth();
  const forceUpdate = React.useReducer(() => ({}), {})[1];
  const { isFastDelivery, setIsFastDelivery } = useFastDelivery();
  const { selectedChannel } = useChannelContext();
  const { setVendor } = useVendor();
  const { navigateTo } = useRoutes();
  const {
    openProductDialog,
    setLoading,
    isLoading: isPageLoading,
  } = usePageLayout();
  const getVariantsQuery = (ids: string[]): string[] => {
    if (ids?.length > 0) {
      return ids.map((id) => 'variants.id:"' + id + '"');
    }
    return [];
  };
  const getConfiguration = () => {
    const filtersList = [
      `is_long_time_out_of_stock:false AND channel_id:${selectedChannel?.id}`,
    ];
    if (fromCategory) filtersList.push(`categories.slug:${fromCategory}`);
    if (ids && ids?.length > 0)
      filtersList.push(
        `(${ids
          .map((id) => 'id:"' + id + '"')
          .concat(getVariantsQuery(variantsIds))
          .join(' OR ')})`,
      );
    else if (variantsIds && variantsIds?.length > 0)
      filtersList.push(
        `(${variantsIds.map((id) => 'variants.id:"' + id + '"').join(' OR ')})`,
      );
    if (fromVendor) filtersList.push(`variants.vendor.slug:${fromVendor}`);
    if (fromCollection) filtersList.push(`collections.slug:${fromCollection}`);
    if (fromDepartment) filtersList.push(`department:${fromCollection}`);
    if (excludingSku) filtersList.push(`NOT sku:${excludingSku}`);
    if (!user) filtersList.push(`NOT categories.slug:alcoholic-beverages`);
    if (!includeOutOfStock) filtersList.push(`in_stock:true`);
    if (isFastDelivery) filtersList.push(`variants.is_fast_delivery:true`);
    const configuration: { [k: string]: any } = { hitsPerPage: hitsPerPage };
    if (filtersList) configuration.filters = filtersList.join(' AND ');
    if (query) configuration.query = query;
    return configuration;
  };
  const { addCartVariant, getCartVariant, removeCartVariant } =
    useCartContext();
  const { checkout } = useCheckoutFlow();

  const { refine } = useConfigure(getConfiguration());
  const { status, scopedResults, results, uiState, indexUiState } =
    useInstantSearch();

  type StatusType = typeof status;
  const {
    currentRefinement: paginationCurrentRefinement,
    canRefine: paginationCanRefine,
    refine: paginationRefine,
  } = usePagination();

  const {
    items: brandItems,
    refine: brandRefine,
    searchForItems: brandSearchForItems,
    canToggleShowMore: brandCanToggleShowMore,
    toggleShowMore: brandToggleShowMore,
    isFromSearch: brandIsFromSearch,
  } = useRefinementList({
    attribute: 'brand',
    showMore: true,
    showMoreLimit: SHOW_MORE_LIMIT,
  });
  const {
    items: vendorItems,
    refine: vendorRefine,
    searchForItems: vendorSearchForItems,
    canToggleShowMore: vendorCanToggleShowMore,
    toggleShowMore: vendorToggleShowMore,
    isFromSearch: vendorIsFromSearch,
  } = useRefinementList({
    attribute: 'variants.vendor.name',
    showMore: true,
    showMoreLimit: SHOW_MORE_LIMIT,
  });
  const {
    items: supervisionItems,
    refine: supervisionRefine,
    searchForItems: supervisionSearchForItems,
    canToggleShowMore: supervisionCanToggleShowMore,
    toggleShowMore: supervisionToggleShowMore,
    isFromSearch: supervisionIsFromSearch,
  } = useRefinementList({
    attribute: 'supervisions.name',
    showMore: true,
    showMoreLimit: SHOW_MORE_LIMIT,
  });
  const {
    items: collectionItems,
    refine: collectionRefine,
    searchForItems: collectionSearchForItems,
    canToggleShowMore: collectionCanToggleShowMore,
    toggleShowMore: collectionToggleShowMore,
    isFromSearch: collectionIsFromSearch,
  } = useRefinementList({
    attribute: 'collections.name',
    showMore: true,
    showMoreLimit: SHOW_MORE_LIMIT,
  });
  const { items: fastDeliveryItems } = useRefinementList({
    attribute: 'variants.is_fast_delivery',
    showMore: false,
  });

  const { refine: clearRefine } = useClearRefinements();
  const {
    options: sortOptions,
    refine: sortRefine,
    currentRefinement: sortCurrentRefinement,
  } = useSortBy({
    items: [
      { value: AlgoliaIndexes.PRODUCTS_MOST_POPULAR, label: 'most popular' },
      { value: AlgoliaIndexes.PRODUCTS_PRICE_ASC, label: 'Price: low to high' },
      {
        value: AlgoliaIndexes.PRODUCTS_PRICE_DESC,
        label: 'Price: high to low',
      },
    ],
  });
  const [filters, setFilters] = useState([]);
  const [filteredVendorsSlug, setFilteredVendorsSlug] = useState([]);

  const {
    hits,
    isLastPage,
    showMore,
    sendEvent,
    results: hitsResults,
  } = useInfiniteHits();
  const [isLoading, setIsLoading] = useState(false);
  const isByQuantity = (product: NewProduct): boolean =>
    getVariantByQuantityValue(product.variants[0]) != null;
  const getVariantByQuantityValue = (variant: NewVariant): string => {
    if (variant.attributes?.length > 0) {
      for (let index = 0; index < variant.attributes.length; index++) {
        const element = variant.attributes[index];
        if (element.key === 'by_quantity') return element.value;
      }
    }
  };
  const isVariantMatchByQuantityValue = (
    product: NewProduct,
    variant: NewVariant,
  ): boolean => {
    const productValue: string = product.objectID.endsWith('-case')
      ? 'case'
      : 'single';
    return getVariantByQuantityValue(variant) === productValue;
  };

  useEffect(() => {
    paginationRefine(0);
  }, [
    fromCategory,
    query,
    scrollableTargetId,
    fromCollection,
    fromVendor,
    fromDepartment,
    isFastDelivery,
  ]);

  const isForCurrentCheckout = (variant: NewVariant) => {
    if (!variant?.line?.quantity) {
      return false;
    }
    if (!variant?.line?.order) {
      return checkout?.type !== CheckoutTypeEnum.EDIT_ORDER;
    } else {
      return variant?.line?.order?.id === checkout?.editingOrder?.id;
    }
  };

  useEffect(() => {
    const isFillObject = (obj) => {
      return obj && Object.keys(obj)?.length > 0;
    };
    const loadingStatus = (['loading', 'stalled'] as StatusType[]).includes(
      status,
    );
    setIsLoading(loadingStatus);
    const isToLoad = loadingStatus && paginationCurrentRefinement === 0;
    if (!waitLoadingForCategories && isFillObject(uiState[hitsResults.index])) {
      if (isToLoad) {
        if (!hits) {
          setLoading(isToLoad);
        }
      } else {
        setLoading(isToLoad);
      }
    }
  }, [status]);
  useEffect(() => {
    setFiltersDada();
    setFilteredVendorsSlug(
      vendorItems?.filter((item) => item.isRefined)?.map((item) => item.value),
    );
  }, [vendorItems, brandItems, supervisionItems, collectionItems, sortOptions]);
  const setFiltersDada = () => {
    const sort = {
      name: 'sort by',
      multipleChoice: false,
      isFromSearch: false,
      refine: sortRefine,
      options: brandItems
        ? sortOptions?.map((item) => {
            return {
              name: item.label.toLowerCase(),
              value: item.value,
              active: sortCurrentRefinement == item.value,
            };
          })
        : [],
    };
    const brand = {
      name: 'brand',
      multipleChoice: true,
      refine: brandRefine,
      isFromSearch: brandIsFromSearch,
      loadMore:
        brandCanToggleShowMore && SHOW_MORE_LIMIT > brandItems.length
          ? brandToggleShowMore
          : null,
      // onSearch: brandSearchForItems ? (value) => alert('onSearch') : null,
      onSearchChange: brandSearchForItems
        ? (value) => brandSearchForItems(value)
        : null,
      options: brandItems
        ? brandItems?.map((item) => {
            return {
              name: item.label.toLowerCase(),
              value: item.value,
              active: item.isRefined,
              count: item.count,
            };
          })
        : [],
    };
    const vendor = {
      name: 'vendor',
      multipleChoice: true,
      refine: vendorRefine,
      isFromSearch: vendorIsFromSearch,
      loadMore:
        vendorCanToggleShowMore && SHOW_MORE_LIMIT > vendorItems.length
          ? vendorToggleShowMore
          : null,
      // onSearch: vendorSearchForItems ? (value) => alert('onSearch') : null,
      onSearchChange: vendorSearchForItems
        ? (value) => vendorSearchForItems(value)
        : null,
      options: vendorItems
        ? vendorItems?.map((item) => {
            return {
              name: item.label.toLowerCase(),
              value: item.value,
              active: item.isRefined,
              count: item.count,
            };
          })
        : [],
    };
    const supervision = {
      name: 'supervision',
      multipleChoice: true,
      refine: supervisionRefine,
      isFromSearch: supervisionIsFromSearch,
      loadMore:
        supervisionCanToggleShowMore &&
        SHOW_MORE_LIMIT > supervisionItems.length
          ? supervisionToggleShowMore
          : null,
      // onSearch: vendorSearchForItems ? (value) => alert('onSearch') : null,
      onSearchChange: supervisionSearchForItems
        ? (value) => supervisionSearchForItems(value)
        : null,
      options: supervisionItems
        ? supervisionItems?.map((item) => {
            return {
              name: item.label.toLowerCase(),
              value: item.value,
              active: item.isRefined,
              count: item.count,
            };
          })
        : [],
    };

    const collections = {
      name: 'holidays',
      multipleChoice: true,
      refine: collectionRefine,
      isFromSearch: false,
      loadMore:
        collectionCanToggleShowMore && SHOW_MORE_LIMIT > collectionItems.length
          ? collectionToggleShowMore
          : null,
      // onSearch: vendorSearchForItems ? (value) => alert('onSearch') : null,
      // onSearchChange: collectionSearchForItems
      //   ? (value) => collectionSearchForItems(value)
      //   : null,
      options: collectionItems
        ? collectionItems?.map((item) => {
            return {
              name: item.label.toLowerCase(),
              value: item.value,
              active: item.isRefined,
              count: item.count,
            };
          })
        : [],
    };

    const filtersList = [sort, brand];
    if (!fromVendor) filtersList.push(vendor);
    filtersList.push(supervision, collections);
    setFilters(
      filtersList.filter((v) => v.options.length > 0 || v?.isFromSearch),
    );
  };
  const onFilterChange = (value) => {
    if (!value) {
      sortRefine(INITIAL_INDEX);
      clearRefine();
      return;
    }
    for (let filterIndex = 0; filterIndex < value.length; filterIndex++) {
      const filter = value[filterIndex];
      const updatedValues = [];
      let isChanged = false;
      for (const element of filter.options) {
        const option = element;
        if (option.changed) isChanged = true;
        if (!filter.multipleChoice) {
          if (option.active) {
            updatedValues.push(element.value);
            break;
          }
        } else {
          if (option.changed) {
            updatedValues.push(element.value);
            if (!filter.multipleChoice) break;
          }
        }
      }
      if (
        (!filter.multipleChoice && isChanged && updatedValues.length > 0) ||
        (filter.multipleChoice && isChanged)
      ) {
        if (isChanged && updatedValues.length == 0) clearRefine();
        updatedValues.forEach((element) => {
          filters[filterIndex].refine(element);
        });
      }
    }
  };
  const setProductsData = (products: NewProduct[]): NewProduct[] => {
    products.forEach((product: NewProduct) => {
      if (product.variants.length === 0) return;
      if (isFastDelivery)
        product.variants = product.variants.filter(
          (variant) => variant.is_fast_delivery,
        );
      //TODO: leave it for levi, its for removing the wrong single/case in pupolar items
      // if (ids)
      //   product.variants = product.variants.filter((variant) => {
      //     if (!isByQuantity(product)) return true;
      //     const oneOfMatchVariantsIds = product.variants.find((v) =>
      //       ids.includes(v.id)
      //     );
      //     if (
      //       oneOfMatchVariantsIds &&
      //       isVariantMatchByQuantityValue(product, oneOfMatchVariantsIds)
      //     )
      //       return true;
      //     return false;
      //   });
      if (fromVendor)
        product.variants = product.variants.filter(
          (variant) => variant.vendor.slug === fromVendor,
        );
      if (variantsIds)
        if (product.variants.find((variant) => variant.id in variantsIds)) {
          product.variants = product.variants.filter(
            (variant) => variant.id in variantsIds,
          );
        }
      if (filteredVendorsSlug.length > 0)
        product.variants = product.variants.filter((variant) =>
          filteredVendorsSlug.includes(variant.vendor.name),
        );
      product.variants = product.variants.map((variant) => {
        if (
          isByQuantity(product) &&
          !isVariantMatchByQuantityValue(product, variant)
        )
          return variant;
        return (
          getCartVariant(variant.id) || {
            ...variant,
            line: isForCurrentCheckout(variant) ? { ...variant?.line } : {},
          }
        );
      });
      product.variants.forEach((variant) => {
        variant.vendor = {
          ...variant.vendor,
          channel: {
            id: product.channel_id,
          },
        };
        variant.promotion_images = product.promotion_images;
      });
    });

    return products.filter((product) => {
      if (product.variants.length === 0) return false;
      return (
        !isByQuantity(product) ||
        product.variants.some((variant) =>
          isVariantMatchByQuantityValue(product, variant),
        )
      );
    });
  };
  const [fetchProductsUserData, {}] = useProductsUserDataLazyQuery({
    fetchPolicy: 'network-only',
  });

  const sortProducts = (hits: NewProduct[]): NewProduct[] => {
    let hitsItems = [];
    const isLegalSrtProductsIndexReceived =
      sortProductsIndex && sortProductsIndex?.length === hits?.length;
    if (isLegalSrtProductsIndexReceived) {
      /// So as not to do it every turn of the "every" loop in next lines
      hitsItems = hits?.map((item) => item?.id);
    }

    ///Included in one condition so that the sort function always returns a value
    if (
      isLegalSrtProductsIndexReceived &&
      sortProductsIndex?.every(
        (node) =>
          hitsItems.includes(node?.id) && typeof node?.index === 'number',
      )
    ) {
      return hits?.sort(
        (a, b) =>
          sortProductsIndex?.find((node) => node?.id === a?.id)?.index -
          sortProductsIndex?.find((node) => node?.id === b?.id)?.index,
      );
    }
    return hits;
  };

  let productsData = setProductsData(
    sortProducts(hits as unknown as NewProduct[]),
  );
  const getProductsIds = (productsData: NewProduct[]) => {
    return productsData?.map((node) => node?.id) || [];
  };

  // const getLoadMoreProductsByFilter = () => productsData?.filter(product => !allUserProductsData?.map(userData => userData?.productId)?.includes(product?.id)) || []
  const getLoadMoreIds = (): string[] =>
    getProductsIds(
      productsData?.slice(
        productsData?.length - hitsPerPage,
        productsData?.length,
      ) || [],
    );

  const getAndSetProductsUserData = async () => {
    if (user && productsData?.length > 0) {
      const objectsIds = getLoadMoreIds();
      const response = await fetchProductsUserData({
        variables: { ids: objectsIds },
      });
      const productsUserDataList = response.data.productsUserData;
      productsData.forEach((product) => {
        const node = productsUserDataList?.find(
          (productUserData) => product?.id === productUserData?.productId,
        );
        if (node) {
          product.lastOrdered = node.lastOrdered;
        }
      });
      forceUpdate();
    }
  };
  useEffect(() => {
    getAndSetProductsUserData();
  }, [productsData?.length, productsData[0]?.objectID]);

  // const isPaginationDeed = productsData?.length - 1 > hitsPerPage
  // const isSplitProductsIdsByHitsPerPageAvailable = hitsPerPage > 0 && productsData?.length > 0 && isPaginationDeed

  const rowRender = (product: NewProduct, index): JSX.Element => {
    const orderedVariantIndex = product.variants.findIndex(
      (variant) => variant.line?.quantity > 0,
    );
    let variant =
      orderedVariantIndex > 0
        ? product.variants[orderedVariantIndex]
        : product.variants[0];
    variant.line = variant.line || {};

    const getHitNodeId = (node) =>
      (node as unknown as NewProduct)?.id || node?.objectID;

    const getProductQueryId = (product: NewProduct): string => {
      return hits?.find((node) => getHitNodeId(node) === product?.id)
        ?.__queryID;
    };

    const onItemDetailsClick = () => {
      sendEvent(
        'click',
        hits?.find((node) => getHitNodeId(node) === product?.id),
        'product clicked',
      );
      const queryId = getProductQueryId(product);
      openProductDialog({
        product_id: product.id,
        product: { ...product },
        queryId,
        resultsIndexName: hitsResults.index,
        currentVariant:
          product?.variants?.length > 0
            ? product?.variants?.find((v) => v?.line?.quantity > 0) ||
              product?.variants[0]
            : undefined,
      });
    };

    return (
      <ItemDetails
        itemDetailsPlusNumberDataTestId={cartIds.itemDetailsPlus}
        itemDetailsdataTestId={searchFieldIds.itemDetails}
        containerClassName={classNames(
          itemClassName,
          returnAsSimpleList && styles.returnAsSimpleList,
        )}
        lastPurchase={product?.lastOrdered}
        key={index}
        name={isByQuantity(product) ? variant.name : product.name}
        brand={product.brand}
        unit_size={variant.unit_size}
        size_weight={variant.size_weight}
        onClick={onItemDetailsClick}
        onPlus={(value) => {
          if (value === 1) {
            sendConvertedEvent({
              objectID: product?.id,
              userId: user?.id,
              eventName: ProductEvents.ADDED_TO_CART,
              indexName: hitsResults.index,
              queryID: getProductQueryId(product),
            });
          }
          // variant.line.quantity = value;
          addCartVariant(variant, value);
        }}
        onMinus={(value) => {
          // variant.line.quantity = value;
          addCartVariant(variant, value);
          // removeCartVariant(variant);
        }}
        is_fast_delivery={variant.is_fast_delivery}
        promotion_images={product.promotion_images}
        collections={product.collections}
        supervisions={product.supervisions}
        withQuantityControl={
          !(variant.attributes?.length > 0) ||
          (isByQuantity(product) && variant.attributes?.length == 1) ||
          orderedVariantIndex >= 0
        }
        imageSrc={variant.thumbnail || null}
        by_weight={variant.by_weight}
        in_stock={variant.in_stock}
        isVertical={isVertical}
        value={variant.line?.quantity || 0}
        sale_price={variant.sale_price || 0}
        price={variant.price || 0}
        vendor={variant.vendor}
        onVendorClick={(vendor) => {
          setVendor(vendor);
          navigateTo({ route: `/stores/${vendor?.slug}` });
        }}
      />
    );
  };

  const bodyTitle = (
    <div className={styles.filtersWrapper}>
      <div data-testid={searchFieldIds.title}>{title}</div>
      <div className={styles.filters}>
        {showFilters &&
          collectionItems.findIndex(
            (element) => element.label.toLowerCase() === 'kosher for passover',
          ) >= 0 && (
            <Button
              variant="outline"
              onClick={() => {
                filters.forEach((f) => {
                  if (f.name !== 'holidays') return;
                  f.options.forEach((o) => {
                    if (o.name !== 'kosher for passover') return;
                    o.active = !o.active;
                    o.changed = true;
                    onFilterChange(filters);
                  });
                });
              }}
              active={
                collectionItems.findIndex(
                  (element) =>
                    element.label.toLowerCase() === 'kosher for passover' &&
                    element.isRefined,
                ) >= 0
              }
              title="Pesach Store"
            />
          )}

        {showFilters &&
          selectedChannel?.isSupportFastDelivery &&
          fastDeliveryItems.findIndex((e) => e.value == 'true') >= 0 && (
            <Button
              data-testid={cartIds.fastDeliveryGroceryButton}
              variant="outline"
              onClick={setIsFastDelivery}
              active={isFastDelivery}
              icon={
                <FastDeliveryFilterIcon
                  fill={isFastDelivery ? 'white' : 'black'}
                  stroke={'none'}
                />
              }
            />
          )}
        {showFilters && (
          <Filters
            filterButtonDataTestId={searchFieldIds.filtersButton}
            clearButtonDataTestId={searchFieldIds.clearButton}
            saveButtonDataTestId={searchFieldIds.saveButton}
            collapsibleDataTestId={searchFieldIds.collapsibleFilter}
            onChange={onFilterChange}
            filters={filters}
          />
        )}
      </div>
    </div>
  );

  const renderList = () => (
    <InfiniteList
      // key={`${user?.id}${productsData[0]?.id}${counter}`}
      scrollableTargetId={scrollableTargetId}
      className={className}
      isWrap={true}
      items={!maxHits ? productsData : productsData.slice(0, maxHits)}
      loadMoreItems={showMore}
      totalItems={
        !maxHits
          ? isLastPage || productsData.length === 0
            ? productsData.length
            : productsData.length + 1
          : maxHits
      }
      rowRender={rowRender}
      isLoading={isLoading}
      clickToLoad={clickToLoad}
    />
  );

  return (
    <>
      {returnAsSimpleList ? (
        !maxHits ? (
          productsData?.map(rowRender)
        ) : (
          productsData?.slice(0, maxHits).map(rowRender)
        )
      ) : title ? (
        <TitledContent title={bodyTitle}>{renderList()}</TitledContent>
      ) : (
        renderList()
      )}
    </>
  );
};

export const ProductsListWidget = (props: Props) => {
  return (
    <InstantSearchWrapper indexName={INITIAL_INDEX}>
      <ProductsList {...props} />
    </InstantSearchWrapper>
  );
};
