import { usePageLayout } from '@components/Content/PageLayout/context';
import useWindowDimensions, {
  scrollToTheTopOfTheElement,
} from '@hooks/useWindowDimension';
import { NextRouter, useRouter } from 'next/router';
import React, { useContext, useEffect } from 'react';
import { UrlObject } from 'url';

export type HeaderStatesProps = {
  backward: boolean;
  logo: boolean;
  searchField: boolean;
  deliveryInHeader: boolean;
  actionButtons: boolean;
  cartButton: boolean;
};

type RoutesMapProps = { [page: string]: HeaderStatesProps };

enum Page {
  HOME = 'home',
  CONFIRM_ACCOUNT = 'confirmAccount',
  CATEGORY = 'category',
  STORES_LIST = 'vendors',
  STORE = 'vendor',
  STORE_SEARCH = 'storeSearch',
  ORDER_HISTORY = 'orderHistory',
  ORDER_DETAILS = 'orderDetails',
  SHOPPING_LIST_DETAILS = 'shoppingListDetails',
  CHECKOUT = 'checkout',
  SEARCH = 'search',
  MY_ACCOUNT = 'myAccount',
  RETURN_POLICY = 'returnPolicy',
  TERMS_PRIVACY = 'termsPrivacy',
  SHOPPING_LISTS = 'shoppingLists',
  POPULAR_ITEMS = 'popularItems',
  CONTACT_US = 'contactUs',
  FAQ = 'faq'
}

const HeaderStates: RoutesMapProps = {
  [Page.ORDER_DETAILS]: {
    backward: true,
    logo: true,
    searchField: true,
    deliveryInHeader: true,
    actionButtons: true,
    cartButton: true,
  },
  [Page.SHOPPING_LIST_DETAILS]: {
    backward: true,
    logo: true,
    searchField: true,
    deliveryInHeader: true,
    actionButtons: true,
    cartButton: true,
  },
  [Page.CATEGORY]: {
    backward: true,
    logo: true,
    searchField: true,
    deliveryInHeader: true,
    actionButtons: true,
    cartButton: true,
  },
  [Page.STORES_LIST]: {
    backward: true,
    logo: true,
    searchField: true,
    deliveryInHeader: true,
    actionButtons: true,
    cartButton: true,
  },
  [Page.STORE]: {
    backward: true,
    logo: true,
    searchField: true,
    deliveryInHeader: true,
    actionButtons: true,
    cartButton: true,
  },
  [Page.STORE_SEARCH]: {
    backward: true,
    logo: true,
    searchField: true,
    deliveryInHeader: true,
    actionButtons: true,
    cartButton: true,
  },

  [Page.HOME]: {
    backward: false,
    logo: true,
    searchField: true,
    deliveryInHeader: true,
    actionButtons: true,
    cartButton: true,
  },
  [Page.RETURN_POLICY]: {
    backward: true,
    logo: true,
    searchField: false,
    deliveryInHeader: true,
    actionButtons: true,
    cartButton: true,
  },
  [Page.CONTACT_US]: {
    backward: true,
    logo: true,
    searchField: true,
    deliveryInHeader: true,
    actionButtons: true,
    cartButton: true,
  },
  [Page.TERMS_PRIVACY]: {
    backward: true,
    logo: true,
    searchField: false,
    deliveryInHeader: true,
    actionButtons: true,
    cartButton: true,
  },
  [Page.ORDER_HISTORY]: {
    backward: true,
    logo: true,
    searchField: true,
    deliveryInHeader: true,
    actionButtons: true,
    cartButton: true,
  },
  [Page.SHOPPING_LISTS]: {
    backward: true,
    logo: true,
    searchField: true,
    deliveryInHeader: true,
    actionButtons: true,
    cartButton: true,
  },
  [Page.POPULAR_ITEMS]: {
    backward: true,
    logo: true,
    searchField: false,
    deliveryInHeader: true,
    actionButtons: true,
    cartButton: true,
  },
  [Page.CHECKOUT]: {
    backward: true,
    logo: true,
    searchField: false,
    deliveryInHeader: false,
    actionButtons: true,
    cartButton: false,
  },
  [Page.SEARCH]: {
    backward: true,
    logo: true,
    searchField: true,
    deliveryInHeader: true,
    actionButtons: true,
    cartButton: true,
  },
  [Page.MY_ACCOUNT]: {
    backward: true,
    logo: true,
    searchField: true,
    deliveryInHeader: true,
    actionButtons: true,
    cartButton: true,
  },
};

type RouteProps = { [page: string]: string };

const routes: RouteProps = {
  [Page.HOME]: '/',
  [Page.CONFIRM_ACCOUNT]: '/auth/confirm-account',
  [Page.CATEGORY]: '/[...categorySlug]',
  [Page.STORES_LIST]: '/stores',
  [Page.STORE]: '/stores/[vendorSlug]',
  [Page.STORE_SEARCH]: '/stores/[vendorSlug]/search',
  [Page.SHOPPING_LIST_DETAILS]: '/shopping-list-details/[id]',
  [Page.ORDER_DETAILS]: '/order-details/[id]',
  [Page.ORDER_HISTORY]: '/order-history',
  [Page.POPULAR_ITEMS]: '/my-popular-items',
  [Page.SHOPPING_LISTS]: '/shopping-lists',
  [Page.CHECKOUT]: '/checkout',
  [Page.SEARCH]: '/search',
  [Page.MY_ACCOUNT]: '/my-account',
  [Page.RETURN_POLICY]: '/return-policy',
  [Page.TERMS_PRIVACY]: '/terms-privacy',
  [Page.CONTACT_US]: '/contact-us',
  [Page.FAQ]: '/faq'
};
const initialRoutesState = {
  router: null,
  pages: Page,
  routes: routes,
  currentPath: null,
  isCurrentPage: null,
  isDevEnvironment: null,
  navigateTo: null,
  back: null,
  getCurrentPageHeaderStates: null,
};

type RouterOptionsProps = {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
  queryParams?: string[];
};

export type NavigationProps = {
  route?: Page | string;
  url?: UrlObject;
  isReplace?: boolean;
  options?: RouterOptionsProps;
  showLoader?: boolean;
};

export const RoutesContext = React.createContext<{
  router: NextRouter;
  pages: typeof Page;
  routes: RouteProps;
  currentPath: string;
  isDevEnvironment: boolean;
  isCurrentPage: (page: Page) => boolean;
  navigateTo: (input: NavigationProps) => Promise<boolean>;
  back: () => void;
  getCurrentPageHeaderStates: () => HeaderStatesProps;
}>(initialRoutesState);

export function useRoutes() {
  const context = useContext(RoutesContext);
  if (!context)
    throw new Error('usePageLayout must be used within PageLayoutContext');
  return context;
}

export function RoutesContextProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  const isDevEnvironment: boolean =
    process.env.NEXT_PUBLIC_BACKEND_API_URL.toString().includes('.dev');

  const router = useRouter();
  const { setLoading } = usePageLayout();
  const { isLargeTablet } = useWindowDimensions();

  const isCurrentPage = (page: Page) => {
    return router.pathname == routes[page];
  };

  const currentPage: () => Page = () => {
    const route = Object.entries(routes).filter((k, v) => {
      return k[1] === router.pathname;
    });
    return route && route.length > 0 ? (route[0][0] as Page) : null;
  };

  const getCurrentPageHeaderStates = () => {
    const page = currentPage();
    const states = HeaderStates[page]
      ? HeaderStates[page]
      : HeaderStates[Page.HOME];
    // if (page == Page.SEARCH) states.searchField = isLargeTablet;
    return states;
  };

  const addQueryParams = (queryParams: string[]) => {

    const findStartWith = (startWith:string) => {
      const found = queryParams?.find(q => q?.startsWith(startWith))
      return found
    }
    const routerQueryParams = Object.keys(router.query);
    const previousQuery = routerQueryParams.filter((key) => {
      if (queryParams?.includes(key)) {
        return `${key}=${router.query[key]}`;
      }
    });
    if(queryParams?.includes(findStartWith('returnUrl'))){
      previousQuery.push(findStartWith('returnUrl'))
    }
    return previousQuery.length > 0 ? `?${previousQuery.join('&')}` : '';
  };

  const navigateTo = async ({
    route,
    url,
    options,
    isReplace,
    showLoader,
  }: NavigationProps) => {
    if(showLoader){
      setLoading(true)
    }
    if ((route && url) || (!route && !url))
      throw 'Input must have only one of parameters url | route';

    let routeWithType;
    const routeAsPath = router.asPath.slice(1);
    if (routes[route]) {
      routeWithType = route as Page;
      if (isCurrentPage(routeWithType)) return;
    } else if (
      router.asPath == (route as string) ||
      routeAsPath == (route as string)
    ) {
      return;
    }

    // we check if there is options because there is a different between
    // route of side bar and search
    // Just in side bar navigation there are option
    // if (options) {
    // setLoading(true);
    // }

    if (options?.scroll) scrollToTheTopOfTheElement('pageBodyContainer');
    let wantedRoute: UrlObject | string = routes[routeWithType]
      ? routes[routeWithType]
      : route;
    const queryParams: string[] = options?.queryParams || [];
    queryParams.push('now');
    let params = addQueryParams(queryParams);
    if (wantedRoute?.includes('?') && params)
      params = `&${params.substring(1)}`;
    wantedRoute = wantedRoute?.concat(params) ?? url;
    return isReplace
      ? router.replace(wantedRoute, undefined, options)
      : router.push(wantedRoute, wantedRoute, options);
  };

  const back = async () => {
    setLoading(true);
    const currentPath = router.asPath;

    // Check if the last part of the URL is a parameter
    const parts = currentPath.split('/');
    const lastPart = parts[parts.length - 1];
    if (router?.query?.id != undefined) {
      await router.back();
      setLoading(false);
      return;
    }

    const lastSlashIndex = currentPath.lastIndexOf('/');
    const previousPath = currentPath.substring(0, lastSlashIndex);

    // Remove the last parameter
    const queryParamsIndex = previousPath.lastIndexOf('?');
    const finalPath =
      queryParamsIndex !== -1
        ? previousPath.substring(0, queryParamsIndex)
        : previousPath;

    // Make sure the finalPath has at least one part before '/'
    const finalParts = finalPath.split('/');
    if (finalParts.length === 3) {
      finalParts.pop(); // Remove the last part
    }

    const fixedPath = finalParts.join('/');

    // Check if the fixedPath is empty (no parts before '/')
    // If it's empty, set it to '/'
    const newPath = fixedPath || '/';

    await router.push(newPath);
    setLoading(false);
  };

  useEffect(() => {
    if (!isCurrentPage(Page.HOME)) {
      // setLoading(false);
    }
  }, [router.asPath, isLargeTablet]);

  return (
    <RoutesContext.Provider
      value={{
        router: router,
        pages: Page,
        routes: routes,
        currentPath: router.pathname,
        isDevEnvironment,
        isCurrentPage,
        navigateTo,
        back,
        getCurrentPageHeaderStates,
      }}
    >
      {children}
    </RoutesContext.Provider>
  );
}
