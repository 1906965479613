import { useEffect, useState } from 'react';

import { useCheckoutShippingAddressContext } from './ShippingAddressStep/context';
import {
  Button,
  Dialog,
} from '@teddly/teddly-ui-components';
import ShippingMethodsList from '@components/Pages/Checkout/components/ShippingMethodsList';
import { ids } from '../../../../../cypress/pages/checkout/CheckoutPage.cy';
import useWindowDimensions from '@hooks/useWindowDimension';
import { UserAddressType } from '@app/user/hooks';

type PickShippingAddressProps = {
  addresses?: UserAddressType[];
  isOpen?: boolean;
  onClose?: () => void;
  onDelete?: (id: string) => void;
};

export default function PickShippingAddress({
  addresses = [],
  onClose,
  isOpen,
  onDelete,
}: PickShippingAddressProps) {
  const {
    openEditAddressForm,
    openAddAddressForm,
    setSelectedShippingAddress,
    selectedShippingAddress,
    closeCurrentDialog,
  } = useCheckoutShippingAddressContext();
  const [newSelectedAddress, setNewSelectedAddress] = useState(
    selectedShippingAddress
  );

  useEffect(() => {
    if (!newSelectedAddress && selectedShippingAddress) {
      setNewSelectedAddress(selectedShippingAddress);
    }
  }, [selectedShippingAddress]);

  const onSelectMethodHandler = async (method) => {
    setNewSelectedAddress(method);
  };
  const close = () => {
    onClose()
    setNewSelectedAddress(selectedShippingAddress)
  }

  const { isLargeTablet } = useWindowDimensions();


  return (
    <Dialog
      title="Pick Preferred delivery address"
      isOpen={isOpen && addresses?.length > 0}
      onClose={close}
      style={{width: !isLargeTablet ? '740px' : null}}
      footerProps={{
        children: [
          <Button
            variant={'outline'}
            onClick={openAddAddressForm}
            title={'Add New'}
          />,
          <Button
            data-testid={ids.changeAddressButton}
            disabled={newSelectedAddress?.id == selectedShippingAddress?.id}
            onClick={(event) => {
              setSelectedShippingAddress(newSelectedAddress);
              closeCurrentDialog();
            }}
            title={'Change Address'}
          />,
        ],
      }}
    >
      <div style={{maxWidth:"inherit"}}>
        <ShippingMethodsList
          methods={addresses}
          onDeleteMethod={async (id: string) => {
            onDelete(id);
          }}
          selectedMethodId={newSelectedAddress?.id}
          onEditMethod={openEditAddressForm}
          onSelectMethod={onSelectMethodHandler}
        />
      </div>
    </Dialog>
  );
}
