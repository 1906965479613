import { useUserAddresses } from '@app/user/hooks';
import {
  useContext,
  createContext,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { CurrentOpenDialog } from '../../context';
import {
  AuthData,
  useCheckoutFlow,
  usePaymentProviderAuthData,
} from '../../context/checkout-flow';
import { CountryCode, useAuth } from 'teddly-sdk';
import { IAddress } from 'teddly-sdk/lib/api/Checkout/types';
import { StripeCardData, usePaymentSources } from '../../hooks';
import {
  SnackbarVariant,
  usePageLayout,
} from '@components/Content/PageLayout/context';
import { useChannelContext } from '@context/channel/ChannelContext';

export const CheckoutPaymentContext = createContext<{
  openAddPaymentForm: () => void;
  openPickPaymentForm: () => void;
  currentOpenDialog: CurrentOpenDialog;
  closeCurrentDialog: () => void;
  authData: AuthData;
  handleCardAdded: () => void;
  changeCardHandler: (cardInfo: StripeCardData) => void;
  deleteCardHandler: () => void;
  handleBillingAddress: (IAddress) => void;
  selectedCardInfo: StripeCardData;
  isPaymentLoading: boolean;
  isPaymentValid: boolean;
  paymentSourceError: string;
  cards: StripeCardData[];
  isOpenAddPaymentForm: boolean;
  isOpenPickPaymentForm: boolean;
  paymentBillingError: string;
}>(null);

export function CheckoutPaymentProvider({ children }: { children: ReactNode }) {
  const { user } = useAuth();
  const { userAddresses: allAddress } = useUserAddresses();
  const userId = user ? user.id : null;
  const [selectedCardInfo, setSelectedCard] = useState<StripeCardData>(null);
  const { setBillingAddress, checkout } = useCheckoutFlow();
  const [currentOpenDialog, setCurrentOpenDialog] =
    useState<CurrentOpenDialog>(null);
  const {
    result: cards,
    status: paymentSourceStatus,
    error: paymentSourceError,
    refetch: getPaymentSources,
  } = usePaymentSources();

  const { addSnackbar, closeSnackbar } = usePageLayout();

  const [paymentBillingError, setPaymentBillingError] = useState(null);
  const [isNewPaymentAdded, setIsNewPaymentAdded] = useState<boolean>(false);
  const { selectedChannel } = useChannelContext();
  const authData = usePaymentProviderAuthData();
  const [isPaymentValid, setPaymentValid] = useState(false);
  const [allCards, setAllCards] = useState<any>();
  //if there is no user => if sign out or reload the web site
  //then set the payment that is not valid
  useEffect(() => {
    if (!userId) {
      setSelectedCard(null);
      setPaymentValid(false);
    }
  }, [userId]);

  //if return cards from server
  useEffect(() => {
    if (cards && checkout?.lines?.length > 0 && !selectedCardInfo) {
      setFirstCardAsSelected(cards);
    }
    setAllCards(cards);
  }, [cards, checkout?.lines?.length]);

  const updateCardSelected = async (card: StripeCardData) => {
    setSelectedCard(card);
    setCardAddressAsBillingAddress(card);
    setPaymentValid(true);
  };
  const setFirstCardAsSelected = (cards: StripeCardData[]) => {
    const firstCardOfCurrentChannel = cards?.length > 0 ? cards[0] : null;
    if (firstCardOfCurrentChannel && selectedChannel) {
      updateCardSelected(firstCardOfCurrentChannel);
    }
  };

  const setCardAddressAsBillingAddress = async (card: StripeCardData) => {
    const address = card?.creditCardInfo?.billingAddress;
    const response = await handleBillingAddress({
      ...address,
      country: {
        code: CountryCode.US,
        country: 'United States of America',
      },
    });
    if (!response?.billingAddress) {
      setSelectedCard(null);
      setPaymentValid(false);
    }
  };
  const handleCardAdded = () => {
    getPaymentSources();
    setIsNewPaymentAdded(false);
    closeCurrentDialog();
  };

  const changeCardHandler = async (cardData: StripeCardData) => {
    updateCardSelected(cardData);
    if (!cardData?.creditCardInfo?.cardId?.startsWith('pm')) {
      getPaymentSources().then((newCards) => {
        setFirstCardAsSelected(newCards);
      });
    }
  };

  const deleteCardHandler = async () => {
    setSelectedCard(null);
    if (cards?.length == 1) {
      setPaymentValid(false);
    }
    await getPaymentSources();
    addSnackbar({
      id: 'deleteCard',
      onClose: () => closeSnackbar('deleteCard'),
      message: 'Payment card is remove successfuly!',
      variant: SnackbarVariant.SUCCESS,
      autoHideDuration: 2000,
    });
  };

  const handleBillingAddress = async (address: IAddress) => {
    if (user) {
      setPaymentBillingError(null);
      const { dataError, functionError, data } = await setBillingAddress(
        address,
        user.email,
      );
      if (dataError || functionError) {
        const snackbarId = 'billing address error';
        addSnackbar({
          id: snackbarId,
          onClose: () => closeSnackbar(snackbarId),
          message: 'An error occurred while updating the billing address',
          variant: SnackbarVariant.ERROR,
        });
      }
      return data;
    }
    return null;
  };
  const isOpenAddPaymentForm =
    currentOpenDialog === CurrentOpenDialog.AddPaymentForm;

  const isOpenPickPaymentForm =
    currentOpenDialog === CurrentOpenDialog.PickPayment;

  const openAddPaymentForm = () =>
    setCurrentOpenDialog(CurrentOpenDialog.AddPaymentForm);

  const openPickPaymentForm = () =>
    setCurrentOpenDialog(CurrentOpenDialog.PickPayment);

  const closeCurrentDialog = () => setCurrentOpenDialog(CurrentOpenDialog.None);
  return (
    <CheckoutPaymentContext.Provider
      value={{
        openAddPaymentForm: openAddPaymentForm,
        openPickPaymentForm: openPickPaymentForm,
        currentOpenDialog: currentOpenDialog,
        closeCurrentDialog: closeCurrentDialog,
        changeCardHandler: changeCardHandler,
        deleteCardHandler: deleteCardHandler,
        authData: authData,
        handleCardAdded: handleCardAdded,
        handleBillingAddress: handleBillingAddress,
        selectedCardInfo: selectedCardInfo,
        isPaymentLoading: paymentSourceStatus === 'loading' ? true : false,
        isPaymentValid: isPaymentValid,
        paymentSourceError: paymentSourceError,
        paymentBillingError: paymentBillingError,
        cards: allCards,
        isOpenAddPaymentForm: isOpenAddPaymentForm,
        isOpenPickPaymentForm: isOpenPickPaymentForm,
      }}
    >
      {children}
    </CheckoutPaymentContext.Provider>
  );
}

export const useCheckoutPaymentContext = () => {
  const context = useContext(CheckoutPaymentContext);

  if (!context)
    throw new Error(
      'useUserAddresses should be used within CheckoutShippingAddressContext',
    );

  return context;
};
