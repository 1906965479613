import React, { ComponentProps, useEffect } from 'react';

import algoliasearch from 'algoliasearch';
// import { Configure, InstantSearch } from 'react-instantsearch';

import { Configure, InstantSearch } from 'react-instantsearch-hooks-web';
import { useAuth } from 'teddly-sdk';
import aa from "search-insights";


//algolia search 
export const AlgoliaIndexes = {
  // PRODUCTS: process.env.NEXT_PUBLIC_PRODUCTS_INDEX || 'teddly_product',
  // PRODUCTS_QUERY:
  //   process.env.NEXT_PUBLIC_PRODUCTS_QUERY_INDEX ||
  //   'teddly_product_query_suggestions',
  // PRODUCTS_PRICE_ASC:
  //   process.env.NEXT_PUBLIC_PRODUCTS_PRICE_ASC || 'teddly_products_price_asc',
  // PRODUCTS_PRICE_DESC:
  //   process.env.NEXT_PUBLIC_PRODUCTS_PRICE_DESC || 'teddly_products_price_desc',
  // PRODUCTS_PRICE_GAP_DESC:
  //   process.env.NEXT_PUBLIC_PRODUCTS_PRICE_GAP_DESC ||
  //   'teddly_products_price_gap_desc',
  // PRODUCTS_MOST_POPULAR: process.env.NEXT_PUBLIC_PRODUCTS_POPULAR || 'teddly_product_popular'



  PRODUCTS: process.env.NEXT_PUBLIC_PRODUCTS_INDEX || 'dev_product_test',
  PRODUCTS_QUERY:
    process.env.NEXT_PUBLIC_PRODUCTS_QUERY_INDEX ||
    'dev_products_query_suggestions',
  PRODUCTS_PRICE_ASC:
    process.env.NEXT_PUBLIC_PRODUCTS_PRICE_ASC || 'dev_products_price_asc',
  PRODUCTS_PRICE_DESC:
    process.env.NEXT_PUBLIC_PRODUCTS_PRICE_DESC || 'dev_products_price_desc',
  PRODUCTS_PRICE_GAP_DESC:
    process.env.NEXT_PUBLIC_PRODUCTS_PRICE_GAP_DESC ||
    'dev_products_price_gap_desc',
  PRODUCTS_MOST_POPULAR: process.env.NEXT_PUBLIC_PRODUCTS_POPULAR || 'dev_products_popular'
};

export const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_API_KEY,
);

export const productSearchIndex = searchClient.initIndex(
  AlgoliaIndexes.PRODUCTS
);
export const productSuggestionsSearchIndex = searchClient.initIndex(
  AlgoliaIndexes.PRODUCTS_QUERY
);

export function InstantSearchWrapper({
  children,
  indexName = AlgoliaIndexes.PRODUCTS,
  ...props
}: { children: React.ReactNode } & Omit<
  ComponentProps<typeof InstantSearch>,
  'searchClient'
>) {
  const { user } = useAuth()
  useEffect(()=>{
    aa('setUserToken', user?.id);
  }, [user])
  return (
    <InstantSearch searchClient={searchClient} indexName={indexName} insights {...props}>
      <Configure hitsPerPage={15} clickAnalytics />
      {children}
    </InstantSearch>
  );
}



export function QuerySuggestionsWrapper({
  children,
  indexName = AlgoliaIndexes.PRODUCTS_QUERY,
  ...props
}: { children: React.ReactNode } & Omit<
  ComponentProps<typeof InstantSearch>,
  'searchClient'
>) {
  const { user } = useAuth()
  useEffect(()=>{
    aa('setUserToken', user?.id);
  }, [user])
  return (
    <InstantSearch searchClient={searchClient} indexName={indexName} insights {...props}>
      <Configure hitsPerPage={3} clickAnalytics />
      {children}
    </InstantSearch>
  );
}
